export const formatResults = (result) => {
  let address;
  let suburb;
  let city;
  let state;
  const fullAddress = result.properties?.address;

  if (result.properties) {
    address =
      fullAddress.road ||
      fullAddress.street ||
      fullAddress.neighbourhood ||
      fullAddress.suburb;
    suburb = !address
      ? fullAddress.suburb || fullAddress.neighbourhood
      : undefined;
    city = fullAddress.city || fullAddress.town || fullAddress.municipality;
    state = fullAddress.state;
  }

  return `${address ? address + ', ' : ''}${
    suburb ? suburb + ', ' : ''
  }${city} - ${state}`;
};
