import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  fullAddress: null,
  searchAddressCoordinates: null,
  exactPropertyCoordinates: null,
  imagesArray: [],
};

const addPropertyFormValuesSlice = createSlice({
  name: 'addPropertyFormValues',
  initialState: initialFormState,
  reducers: {
    setFullAddress(state, action) {
      state.fullAddress = action.payload;
    },
    setImagesArray(state, action) {
      state.imagesArray = action.payload;
    },
    setSearchAddressCoordinates(state, action) {
      state.searchAddressCoordinates = action.payload;
    },
    setExactPropertyCoordinates(state, action) {
      state.exactPropertyCoordinates = action.payload;
    },
  },
});

export const addPropertyFormValuesActions = addPropertyFormValuesSlice.actions;

export default addPropertyFormValuesSlice.reducer;
