import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePropertiesList } from '../services/propertyService';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  BedOutlined,
  DirectionsCarFilledOutlined,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import { useMediaQuery } from '@uidotdev/usehooks';
import housePlaceholder from '../media/housePlaceholder.webp';
const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const MyPropertiesPage = () => {
  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 350px)'
  );
  const navigate = useNavigate();
  const { propertiesList, isLoading, isError } = usePropertiesList();

  useEffect(() => {
    console.log('propertiesList: ', propertiesList);
  }, [propertiesList]);

  return (
    <div className='flex flex-col mt-4 gap-8 items-center px-4'>
      <div>
        <h1 className='text-emerald-400 font-extrabold  text-center text-xl md:text-3xl'>
          Meus Imóveis
        </h1>
      </div>

      {!isLoading &&
        !isError &&
        propertiesList?.map((property) => (
          <div
            key={property._id}
            className='w-11/12 xs:w-11/12 sm:w-4/5 md:w-4/5 lg:w-4/6 h-96 xs:h-2/6 flex flex-col xs:flex-row  bg-slate-50 hover:bg-slate-100 shadow-lg border border-emerald-900 rounded hover:scale-102 duration-500 cursor-pointer'
          >
            <Carousel
              responsive={responsive}
              infinite={true}
              slidesToSlide={1}
              className='w-full xs:w-1/3 h-1/2 xs:h-48'
              swipeable
              draggable
            >
              {[...property.images]
                .sort((a, b) => {
                  if (a.isCover) return -1;
                  if (b.isCover) return 1;
                })
                .map((image) => (
                  <img
                    key={image.name}
                    src={image.url || housePlaceholder}
                    alt='property'
                    className='w-full h-48 object-cover'
                  />
                ))}
            </Carousel>

            <div className='flex flex-col justify-between p-2 h-1/2 xs:h-48 w-full xs:w-2/3 '>
              <div>
                <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-bold tracking-tight text-emerald-700 truncate'>
                  {property.description}
                </p>
                <p className='font-medium text-sm sm:text-base md:text-lg text-gray-500 truncate'>{`${property.address} - ${property.houseNumber} - ${property.neighborhood}`}</p>
              </div>
              <div className='flex justify-between pr-2'>
                <div className='flex flex-col items-center md:flex-row gap-1'>
                  <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.area} m²`}</p>
                </div>
                <div className='flex flex-col items-center md:flex-row gap-1'>
                  <BedOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.bedrooms
                  } ${!isExtraSmallDevice ? 'quartos' : ''}`}</p>
                </div>
                <div className='flex flex-col items-center md:flex-row gap-1'>
                  <ShowerOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.bathrooms
                  } ${!isExtraSmallDevice ? 'banheiros' : ''}`}</p>
                </div>
                <div className='flex flex-col items-center md:flex-row gap-1'>
                  <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.parkingSlots
                  } ${!isExtraSmallDevice ? 'vagas' : ''}`}</p>
                </div>
              </div>
              <div>
                <p className='text-sm'>{`${
                  property.action === 'sale' ? 'Preço:' : 'Aluguel (R$/mês):'
                }`}</p>
                <p className='font-bold'>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(property.price)}
                </p>
              </div>
            </div>
          </div>
        ))}

      <div className='mb-6'>
        <button
          type='button'
          className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
          onClick={() => navigate('/minhas-propriedades/adicionar')}
        >
          Adicionar Imóvel
        </button>
      </div>
    </div>
  );
};

export default MyPropertiesPage;
