import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import {
  drawingButtonsClassName,
  drawButtonClassName,
  removeButtonClassName,
  searchButtonClassName,
} from '../../utils/buttonsClasses';
import { useDispatch, useSelector } from 'react-redux';
import { drawingAreasValuesActions } from '../../store/drawingAreasValues';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { drawingPolygonConfig } from '../../utils/shapes';
const polyUtil = require('polyline-encoded');

const DrawingButtonsDiv = ({ map, polygonHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedDrawnArea, drawnArea } = useSelector(
    (state) => state.drawingAreasValues
  );

  const [searchParams, setSearchParams] = useSearchParams();
  let drawingButtonsControl = null;
  let editableLayers = null;
  let editToolbar = null;
  const drawingButtonsDiv = L.DomUtil.create('div');
  const drawButton = L.DomUtil.create('button');
  const removeButton = L.DomUtil.create('button');
  const searchButton = L.DomUtil.create('button');

  drawButton.innerHTML = 'Desenhar';
  removeButton.innerHTML = 'Limpar';
  searchButton.innerHTML = 'Buscar';
  drawButton.className = drawButtonClassName;
  drawButton.id = 'drawButton';
  removeButton.className = removeButtonClassName;
  removeButton.id = 'removeButton';

  removeButton.disabled = true;
  searchButton.disabled = true;

  searchButton.className = searchButtonClassName;
  searchButton.id = 'searchButton';
  drawingButtonsDiv.className = drawingButtonsClassName;

  const handleSearchClick = () => {
    navigate('/resultado-busca');
  };

  const handleDrawClick = (e) => {
    console.log('Draw button clicked:', e);
    // start drawing mode

    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.remove();
      }
    });

    polygonHandler.enable();

    // console.log('Drawing mode enabled:', drawingButtonsControl);
  };

  const handleRemoveClick = (e) => {
    map.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        layer.editing.disable();
        map.removeLayer(layer);
      }
      drawButton.disabled = false;
      removeButton.disabled = true;
      searchButton.disabled = true;
    });
    dispatch(drawingAreasValuesActions.setSelectedDrawnArea(null));
    dispatch(drawingAreasValuesActions.setDrawnArea(null));
    dispatch(drawingAreasValuesActions.setSelectedDrawnAreaId(null));
    // remove all searchparams from the url
    searchParams.delete('drawnArea');
    setSearchParams(searchParams);
  };

  const handleDrawCreated = (e) => {
    console.log('Draw created:', e);
    map.addLayer(e.layer); // Add this line
    e.layer.editing.enable();

    searchButton.disabled = false;
    drawButton.disabled = true;
    removeButton.disabled = false;

    const bounds = e.layer.getBounds();
    map.flyToBounds(bounds, { padding: [25, 25], animate: true, zoom: 15 });
    // get the coordinates of the polygon and store them in an array
    const array = e.layer
      .getLatLngs()[0]
      .map((coord) => [coord.lat, coord.lng]);
    // put the first coordinate at the end of the array to close the polygon
    array.push(array[0]);

    dispatch(drawingAreasValuesActions.setDrawnArea(array));
    dispatch(drawingAreasValuesActions.setSelectedDrawnArea(null));
    dispatch(drawingAreasValuesActions.setSelectedDrawnAreaId(null));
    const encodedArea = polyUtil.encode(array);
    console.log({ encodedArea });
    setSearchParams({ drawnArea: encodedArea });
  };

  const handleDrawEditVertex = (e) => {
    console.log('Draw edit vertex:', e);
    console.log('Draw edit vertex layer:', e.poly.intersects());

    if (e.poly.intersects()) {
      e.poly.setStyle({
        color: '#FF0000',
        weight: 3,
        opacity: 1,
        fill: true,
        fillColor: '#FF0000',
        fillOpacity: 0.5,
        clickable: true,
      });
      searchButton.disabled = true;
      e.poly.bindTooltip('As linhas não podem se cruzar!', {
        permanent: true,
        direction: 'center',
        sticky: true,
      });
      dispatch(drawingAreasValuesActions.setDrawnArea(null));
      setSearchParams({ drawnArea: null });
    } else {
      e.poly.setStyle({
        color: '#10B981', // color of the original polygon
        weight: 3,
        opacity: 1,
        fill: true,
        fillColor: '#10B981', // color of the original polygon
        fillOpacity: 0.5,
        clickable: true,
      });
      e.poly.unbindTooltip();
      searchButton.disabled = false;
      // get the coordinates of the polygon and store them in an array

      const array = e.poly
        .getLatLngs()[0]
        .map((coord) => [coord.lat, coord.lng]);
      // put the first coordinate at the end of the array to close the polygon
      array.push(array[0]);

      console.log('Array:', array);
      dispatch(drawingAreasValuesActions.setDrawnArea(array));
      dispatch(drawingAreasValuesActions.setSelectedDrawnArea(null));
      dispatch(drawingAreasValuesActions.setSelectedDrawnAreaId(null));
      const encodedArea = polyUtil.encode(array);
      setSearchParams({ drawnArea: encodedArea });
    }
  };
  useEffect(() => {
    map.whenReady(() => {
      if (drawingButtonsControl instanceof L.Control) {
        drawingButtonsControl.remove();
      }

      // if there are values on drawnArea, draw the polygon on the map on edit mode and disable the drawing button and enable the search button
      if (searchParams.get('drawnArea')) {
        const polygon = L.polygon(
          polyUtil.decode(searchParams.get('drawnArea')),
          drawingPolygonConfig
        );
        map.addLayer(polygon);
        map.flyToBounds(polygon.getBounds(), {
          padding: [50, 50],
          animate: true,
        });
        polygon.editing.enable();
        // setSearchParams({ drawnArea: polyUtil.encode(drawnArea) });
        searchButton.disabled = false;
        drawButton.disabled = true;
        removeButton.disabled = false;
      }
      if (drawnArea) {
        const polygon = L.polygon(drawnArea, drawingPolygonConfig);
        map.addLayer(polygon);
        map.flyToBounds(polygon.getBounds(), {
          padding: [50, 50],
          animate: true,
        });
        polygon.editing.enable();
        setSearchParams({ drawnArea: polyUtil.encode(drawnArea) });
        searchButton.disabled = false;
        drawButton.disabled = true;
        removeButton.disabled = false;
      }

      if (selectedDrawnArea) {
        // const polygon = L.polygon(selectedDrawnArea, drawingPolygonConfig);
        // map.addLayer(polygon);
        // map.flyToBounds(polygon.getBounds(), {
        //   padding: [50, 50],
        //   animate: true,
        // });
        // polygon.editing.enable();
        setSearchParams({ drawnArea: polyUtil.encode(selectedDrawnArea) });
        searchButton.disabled = false;
        drawButton.disabled = true;
        removeButton.disabled = false;
      }

      editableLayers = new L.FeatureGroup();
      map.addLayer(editableLayers);
      L.Control.DrawingButtons = L.Control.extend({
        onAdd: function (map) {
          drawButton.innerHTML = 'Desenhar';
          drawButton.className = drawButtonClassName;
          drawButton.id = 'drawButton';
          drawingButtonsDiv.className = drawingButtonsClassName;
          drawingButtonsDiv.appendChild(drawButton);
          drawingButtonsDiv.appendChild(removeButton);
          drawingButtonsDiv.appendChild(searchButton);
          L.DomEvent.on(drawButton, 'click', handleDrawClick);
          L.DomEvent.on(removeButton, 'click', handleRemoveClick);
          L.DomEvent.on(searchButton, 'click', handleSearchClick);
          // disable the drawing button when the drawing is finished and exits the drawing mode, but dont remove the control
          map.on('draw:created', handleDrawCreated);
          map.on('draw:editvertex', handleDrawEditVertex);
          map.on('draw:drawvertex', (e) => {
            console.log('Draw vertex:', e);
            map.eachLayer((layer) => {
              if (layer instanceof L.Polygon) {
                layer.remove();
              }
            });
          });

          return drawingButtonsDiv;
        },
        onRemove: function (map) {
          // Nothing to do here
          console.log('Map removed:', map);
          map.off('draw:created', handleDrawCreated);
          map.off('draw:editvertex', handleDrawEditVertex);
          L.DomEvent.off(drawButton, 'click', handleDrawClick);
          L.DomEvent.off(removeButton, 'click', handleRemoveClick);
          L.DomEvent.off(searchButton, 'click', handleSearchClick);
          // remove the control from the map so it can be added again on re-render
        },
      });
      drawingButtonsControl = new L.Control.DrawingButtons({
        position: 'bottomright',
        draw: {
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
          },
        },
        edit: {
          poly: {
            showArea: true,
            drawError: {
              color: '#10B981',
              message: '<strong>As linhas não podem se cruzar!</strong>',
            },
          },
          edit: false,
          remove: true,
          featureGroup: editableLayers,
        },
      });
      drawingButtonsControl.addTo(map);
    });
  }, [map]);

  return null;
};

export default DrawingButtonsDiv;
