import React from 'react';

import aluguel from '../../media/aluguel.png';
import compra from '../../media/compra.png';
import casa from '../../media/casa.png';
import apartamento from '../../media/apartamento.png';

const RecentSearchesCard = ({ item }) => {
  return (
    <div>
      <div className='justify-between sm:flex'>
        <div className='flex-1'>
          <h3 className='text-sm font-medium text-emerald-700'>{item.title}</h3>
        </div>
      </div>
      <div className='flex mt-3 items-center text-xs space-x-4 space-y-0'>
        <div className='flex gap-2'>
          <img
            src={item.type == 'Alugar' ? aluguel : compra}
            width={20}
            height={20}
          />
          <h1 className='flex items-center text-gray-500'>{item.type}</h1>
        </div>
        <div className='flex gap-2'>
          <img
            src={item.location == 'Residencial' ? casa : apartamento}
            width={20}
            height={20}
          />
          <h1 className='flex items-center text-gray-500'>{item.location}</h1>
        </div>
      </div>
    </div>
  );
};

export default RecentSearchesCard;
