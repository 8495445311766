import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  drawnArea: null,
  selectedDrawnArea: null,
  drawingControl: null,
  selectedDrawnAreaId: null,
};

const drawingAreasValuesSlice = createSlice({
  name: 'drawingAreasValues',
  initialState: initialFormState,
  reducers: {
    setDrawnArea(state, action) {
      state.drawnArea = action.payload;
    },
    setSelectedDrawnArea(state, action) {
      state.selectedDrawnArea = action.payload;
    },
    setDrawingControl(state, action) {
      state.drawingControl = action.payload;
    },
    setSelectedDrawnAreaId(state, action) {
      state.selectedDrawnAreaId = action.payload;
    },
  },
});

export const drawingAreasValuesActions = drawingAreasValuesSlice.actions;

export default drawingAreasValuesSlice.reducer;
