const development = 'http://localhost:3333';
const production = 'https://api.rentia.app.br';
const testServer = 'https://testapi.rentia.app.br';

const satellite =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
const streets =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}';
const topo =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}';

// SERVER URL
export const serverUrl = production;

// Configuration file for the application

export const mapConfig = {
  map_min_zoom: 6,
  map_max_zoom: 18,
  map_default_zoom: 13,
  map_default_center: [-21.769942027474833, -43.329341532811995],
  map_tile_layer: streets,
};

export const drawingMapConfig = {
  map_min_zoom: 6,
  map_max_zoom: 18,
  map_default_zoom: 13,
  map_default_center: [-21.769942027474833, -43.329341532811995],
  map_tile_layer: streets,
};
