import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDrawnArea,
  saveDrawnArea,
  useDrawnAreas,
} from '../../services/drawnAreasService';
import { mutate } from 'swr';
import { drawingAreasValuesActions } from '../../store/drawingAreasValues';
import { Drawer, IconButton } from '@mui/material';
import { Delete, DeleteForeverOutlined } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { drawerValuesActions } from '../../store/drawerValues';
import { Cross1Icon } from '@radix-ui/react-icons';
const polyUtil = require('polyline-encoded');

const DrawnAreasDrawer = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [areaName, setAreaName] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { drawnArea, selectedDrawnAreaId } = useSelector(
    (state) => state.drawingAreasValues
  );
  const { drawnAreasList, isLoading, isError } = useDrawnAreas();

  const { userId } = useSelector((state) => state.loginValues);
  const { loginDrawerOpen, drawnAreasOpen } = useSelector(
    (state) => state.drawerValues
  );
  const dispatch = useDispatch();

  const handleDeleteDrawnArea = async (id) => {
    try {
      await deleteDrawnArea(id);
      mutate('drawnAreas');
      setIsDeleteDialogOpen(false);
      dispatch(drawingAreasValuesActions.setSelectedDrawnArea(null));
      dispatch(drawingAreasValuesActions.setSelectedDrawnAreaId(null));
      toast.success('Área deletada com sucesso!');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao deletar a área');
    }
  };

  const handleSaveDrawnArea = async () => {
    if (!areaName) return;
    console.log(drawnArea);

    const formattedObject = {
      name: areaName,

      // invert the coordinates to match the format of the server (lng, lat)
      location: {
        type: 'Polygon',
        coordinates: [drawnArea.map((coord) => [coord[1], coord[0]])],
      },
    };
    // check if the first and last coordinates are the same
    if (
      formattedObject.location.coordinates[0][0][0] !==
        formattedObject.location.coordinates[0][
          formattedObject.location.coordinates[0].length - 1
        ][0] ||
      formattedObject.location.coordinates[0][0][1] !==
        formattedObject.location.coordinates[0][
          formattedObject.location.coordinates[0].length - 1
        ][1]
    ) {
      formattedObject.location.coordinates[0].push(
        formattedObject.location.coordinates[0][0]
      );
    }
    try {
      await saveDrawnArea(formattedObject);
      mutate('drawnAreas');
      toast.success('Área salva com sucesso!');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedArea = (area) => {
    console.log(area);
    dispatch(
      drawingAreasValuesActions.setSelectedDrawnArea(
        area.location.coordinates[0]
      )
    );
    dispatch(drawingAreasValuesActions.setSelectedDrawnAreaId(area._id));

    dispatch(drawingAreasValuesActions.setDrawnArea(null));

    const encodedArea = polyUtil.encode(area.location.coordinates[0]);

    setSearchParams({ drawnArea: encodedArea });
    dispatch(drawerValuesActions.setDrawnAreasDrawer(false));
  };

  return (
    <Drawer
      open={drawnAreasOpen}
      onClose={() => dispatch(drawerValuesActions.setDrawnAreasDrawer(false))}
      anchor='bottom'
    >
      <div className='flex flex-col w-full h-screen'>
        <div className='relative flex justify-end items-center p-4'>
          <IconButton
            className='hover:cursor-pointer absolute sm:right-6 right-4'
            onClick={() =>
              dispatch(drawerValuesActions.setDrawnAreasDrawer(false))
            }
          >
            <Cross1Icon />
          </IconButton>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <Dialog
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
          >
            <DialogTitle>Deletar Área</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você tem certeza que deseja deletar essa área?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => setIsDeleteDialogOpen(false)}
                className='bg-gray-400 text-white font-bold px-4 py-2 rounded-md hover:bg-gray-500'
              >
                Cancelar
              </button>
              <button
                onClick={() => {
                  handleDeleteDrawnArea(idToDelete);
                }}
                className='bg-red-400 text-white font-bold px-4 py-2 rounded-md hover:bg-red-500'
              >
                Deletar
              </button>
            </DialogActions>
          </Dialog>
          <h1 className='text-emerald-400 font-extrabold  text-center text-xl my-6'>
            Desenhar Áreas
          </h1>
          <input
            type='text'
            onChange={(e) => {
              setAreaName(e.target.value);
            }}
            placeholder='Nome da área'
            className='placeholder:font-light w-4/5 md:flex h-12 px-4 text-black font-bold duration-150 bg-white border rounded-md hover:bg-gray-100 active:shadow-lg'
          />
          <button
            onClick={handleSaveDrawnArea}
            disabled={!drawnArea}
            className='my-4 w-4/5 py-3 text-black font-medium duration-150 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-200 '
          >
            Salvar Área
          </button>
          <h1 className='text-emerald-400 font-extrabold  text-center text-xl my-6'>
            Minhas Áreas
          </h1>
          {!isLoading &&
            !isError &&
            drawnAreasList &&
            !drawnAreasList.message &&
            drawnAreasList.map((area) => (
              <div
                key={area._id}
                className={`flex justify-between w-4/5 border rounded-md p-2 my-2 bg-white shadow-md hover:scale-102 hover:bg-emerald-300 hover:border-black duration-100 cursor-pointer ${
                  selectedDrawnAreaId === area._id
                    ? 'border-emerald-400'
                    : 'border-gray-200'
                }`}
                onClick={() => handleSelectedArea(area)}
              >
                <p>{area.name}</p>
                <IconButton
                  aria-label='delete'
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDeleteDialogOpen(true);
                    setIdToDelete(area._id);
                  }}
                >
                  <Delete fontSize='inherit' />
                </IconButton>
              </div>
            ))}
        </div>
      </div>
    </Drawer>
  );
};

export default DrawnAreasDrawer;
