import React from 'react';
import NavBar from '../components/Layout/NavBar';
import DrawingMap from '../components/Drawing-mode/DrawingMap';

const PhotoServicePage = () => {
  return (
    <div>
      <DrawingMap />
    </div>
  );
};

export default PhotoServicePage;
