import { useEffect, useState } from 'react';
import LoginDrawer from './LoginDrawer';
import MenuDrawer from './MenuDrawer';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../media/real-estate.png';
import { MenuOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';

const NavBar = () => {
  const [state, setState] = useState(false);
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [loginDrawer, setLoginDrawer] = useState(false);
  const { userId } = useSelector((state) => state.loginValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest('.menu-btn')) setState(false);
    };
  }, []);

  return (
    <nav className='flex items-center border-b justify-between p-2 bg-emerald-500 lg:justify-around'>
      <MenuDrawer />
      <LoginDrawer />
      <div className='lg:hidden flex items-center'>
        <button
          onClick={() => dispatch(drawerValuesActions.setMenuDrawer(true))}
          className='px-3 py-1.5 text-sm border-transparent duration-100 border rounded-lg hover:bg-emerald-400'
        >
          <MenuOutlined />
        </button>
      </div>
      <div
        className='flex items-center gap-2 hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <img src={logo} alt='logo' className='w-8 h-8' />
        <h1 className='font-semibold text-xl '>Rentia</h1>
      </div>

      <div className='hidden lg:flex justify-evenly w-2/3'>
        <Link
          to='/resultado-busca?action=rent'
          className={
            'font-semibold text-lg hover:scale-110 px-3 py-1.5 border-b-2 duration-100 border border-transparent hover:border-b-white'
          }
        >
          Alugar
        </Link>
        <Link
          to='/resultado-busca?action=sale'
          className='font-semibold text-lg hover:scale-110 px-3 py-1.5 border-b-2 duration-100 border border-transparent hover:border-b-white'
        >
          Comprar
        </Link>
      </div>
      <div>
        <div className='hidden lg:flex '>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='font-semibold text-lg flex items-center gap-2 px-3 py-1.5 border-transparent text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <PersonOutlineOutlined />
            {userId ? 'Minha conta' : 'Entrar'}
          </button>
        </div>
        <div className='lg:hidden flex items-center'>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <PersonOutlineOutlined />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
