import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  searchMode: '',
  selectedResult: null,
  selectedProperty: null,
};

const searchValuesSlice = createSlice({
  name: 'searchValues',
  initialState: initialFormState,
  reducers: {
    setSearchMode(state, action) {
      state.searchMode = action.payload;
    },
    setSelectedResult(state, action) {
      state.selectedResult = action.payload;
    },
    setSelectedProperty(state, action) {
      state.selectedProperty = action.payload;
    },
  },
});

export const searchValuesActions = searchValuesSlice.actions;

export default searchValuesSlice.reducer;
