import { useEffect, useState } from 'react';
import LoginDrawer from './LoginDrawer';
import MenuDrawer from './MenuDrawer';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../media/real-estate.png';
import {
  ArrowBack,
  MenuBook,
  MenuOutlined,
  PersonOutlineOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import DrawnAreasDrawer from './DrawnAreasDrawer';

const DrawnAreasBar = () => {
  const [state, setState] = useState(false);
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [loginDrawer, setLoginDrawer] = useState(false);
  const { userId } = useSelector((state) => state.loginValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <nav className='flex items-center border-b justify-between p-2 bg-emerald-50 lg:justify-around'>
      <DrawnAreasDrawer />
      <div className='lg:hidden flex items-center'>
        <button
          onClick={() => navigate('/')}
          className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400'
        >
          <ArrowBack /> Sair
        </button>
      </div>

      <div>
        <div className='lg:hidden flex items-center'>
          <button
            onClick={() =>
              dispatch(drawerValuesActions.setDrawnAreasDrawer(true))
            }
            className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <MenuBook /> Áreas Desenhadas
          </button>
        </div>
      </div>
    </nav>
  );
};

export default DrawnAreasBar;
