import React from 'react';
import NavBar from '../components/Layout/NavBar';
import { useForm } from 'react-hook-form';

const SaleFiltersPage = () => {
  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <section className='mt-12 mx-auto max-w-screen-lg'>
        <h1 className='text-center px-4 text-xl md: font-semibold mb-12'>
          Buscar imóveis em Tiguera, Juiz de Fora, MG
        </h1>
        <div className='flex max-w-screen-lg justify-center px-4'>
          <form
            className='grid gap-6 md:gap-x-16 grid-cols-1 md:grid-cols-2'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex items-center gap-2'>
              <label className='w-32'>Tipo de imóvel</label>
              <select
                {...register('type')}
                id='type'
                className='w-52 h-7 px-2 text-sm  text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='apartment'>Apartamento</option>
                <option value='house'>Casa</option>
                <option value='commercial'>Comercial</option>
                <option value='terrain'>Terreno</option>
              </select>
            </div>

            <div className='flex items-center gap-2'>
              <label className='w-32'>Raio de busca</label>
              <select
                {...register('searchRadius')}
                id='searchRadius'
                className='w-52 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='500'>500m</option>
                <option value='1000'>1km</option>
                <option value='2000'>2km</option>
                <option value='3000'>3km</option>
                <option value='4000'>4km</option>
                <option value='5000'>5km</option>
                <option value='6000'>6km</option>
              </select>
            </div>
            <div className='flex items-center gap-2'>
              <label className='w-32'>Anunciado em</label>
              <select
                {...register('dateCreate')}
                id='dateCreate'
                className='w-52 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='1'>24 horas</option>
                <option value='3'>3 dias</option>
                <option value='7'>7 dias</option>
                <option value='14'>14 dias</option>
                <option value='30'>30 dias</option>
              </select>
            </div>
            <div className='flex items-center gap-2'>
              <label className='w-32'>Portaria 24h</label>
              <select
                {...register('concierge')}
                id='concierge'
                className='w-52 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>

            <div className='flex items-center gap-2'>
              <label className='w-32'>Elevador</label>
              <select
                {...register('elevator')}
                id='elevator'
                className='w-52 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>

            <div className='flex items-center gap-2'>
              <label className='w-32'>Valor (R$)</label>
              <div id='price' className='flex gap-2 xs:gap-4'>
                <input
                  {...register('minPrice')}
                  type='number'
                  placeholder='Mínimo'
                  step={100}
                  min={0}
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100  outline-emerald-500'
                />

                <input
                  {...register('maxPrice')}
                  type='number'
                  placeholder='Maximo'
                  step={100}
                  min={0}
                  className='w-24 h-7 p-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100  outline-emerald-500'
                />
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <label className='w-32'>Quartos</label>
              <div id='bedrooms' className='flex gap-2 xs:gap-4'>
                <select
                  {...register('minBedrooms')}
                  id='minBedrooms'
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Mínimo</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxBedrooms')}
                  id='minBedrooms'
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Maximo</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <label className='w-32'>Garagem</label>
              <div className='flex gap-2 xs:gap-4'>
                <select
                  {...register('minParkingSlots')}
                  id='parkingSlots'
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Mínimo</option>
                  <option value='1'>0</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxParkingSlots')}
                  id='parkingSlots'
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Maximo</option>
                  <option value='1'>0</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <label className='w-32'>Banheiros</label>
              <div className='flex gap-2 xs:gap-4'>
                <select
                  {...register('minBathrooms')}
                  id='bathrooms'
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Mínimo</option>
                  <option value='1'>1 </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxBathrooms')}
                  id='bathrooms'
                  className='w-24 h-7 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Maximo</option>
                  <option value='1'>1 </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='md:col-span-2 mx-auto my-8'>
              <button
                type='submit'
                className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg'
              >
                Buscar imóveis
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SaleFiltersPage;
