export const photographerPreviewCardTitle = 'Fotografar imóvel';
export const movePreviewCardTitle = 'Está de mudança?';
export const evaluatePreviewCardTitle = 'Avalie Seu Imóvel';

export const photographerPreviewCardDescription =
  'A aparência do seu imóvel é um fator chave quando os clientes estão pesquisando imóveis. Contrate um fotógrafo profissional e aumente as chances de fechar negócio.';
export const movePreviewCardDescription =
  'Sabemos que mudança é cansativo e causa dores de cabeça. Encontre os melhores serviços de mudança na sua cidade e mude-se com segurança e rapidez para seu novo lar.';
export const evaluatePreviewCardDescription =
  'Não sabe quanto vale seu imóvel ou por qual preço alugar? Encontre um profissional qualificado na sua cidade para avaliar seu imóvel e anuncie no maior portal do Brasil.';

export const jobs = [
  {
    title: 'São Pedro, Juiz de Fora - MG',
    date: '28/04/2024',
    type: 'Alugar',
    location: 'Residencial',
  },
  {
    title: 'Tiguera, Juiz de Fora - MG',
    date: '28/04/2024',
    type: 'Comprar',
    location: 'Residencial',
  },
  {
    title: 'Cascatinha, Juiz de Fora - MG',
    date: '28/04/2024',
    type: 'Alugar',
    location: 'Comercial',
  },
];
