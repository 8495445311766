import {
  ControlPosition,
  MapControl,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import useDebouncedEffect from '../../hooks/useDebounceEffect';

const SearchBox = ({ polygon, setPolygon, map, drawResultPolygon }) => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const inputRef = useRef(null);
  const nominatimRef = useRef(null);

  const places = useMapsLibrary('places');

  const searchNominatim = async (address) => {
    // const response = await fetch(
    //   `https://nominatim.openstreetmap.org/search?q=${address}&format=geojson&extratags=1&addressdetails=1&polygon_geojson=1`
    // );
    const response = await fetch(
      `https://geocoding.geofabrik.de/dac4e2c1d04742bca1e7d874960a49c9/search?q=${address}&format=geojson&extratags=1&addressdetails=1&polygon_geojson=1&countrycodes=br&limit=4`
    );

    const data = await response.json();
    console.log(data);
    setResults(data);
    drawResultPolygon(data);
  };

  useDebouncedEffect(
    () => {
      if (!searchText || searchText.length < 3) return;
      searchNominatim(searchText);
      // cancel previous search if a new keystroke is made before the delay
      return () => {};
    },

    1000,
    [searchText]
  );

  useEffect(() => {
    if (!places) return;
    console.log(inputRef.current);
    const searchBox = new places.Autocomplete(inputRef.current, {
      types: ['geocode'],
      componentRestrictions: { country: ['br'] },
      fields: ['address_components', 'geometry', 'name', 'formatted_address'],
    });

    searchBox.addListener('place_changed', (test) => {
      const place = searchBox.getPlace();

      console.log('place:', place);
      console.log('geometry:', place.geometry.location.toJSON());

      // make a regex to remove the postal code from the address in the format "00000-000, "
      const address = place.formatted_address.replace(/(\d{5}-\d{3}, )/, '');

      console.log('Address:', address);
      const bounds = new window.google.maps.LatLngBounds();
      console.log('Bounds:', bounds);

      if (!place.geometry) return;

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      map.fitBounds(bounds);
      searchNominatim(address);
    });
    console.log('Searchbox:', searchBox);
  }, [places]);

  // function to format the name of the place and return the correct address
  const formatResults = (result) => {
    let address;
    let suburb;
    let city;
    let state;
    const fullAddress = result.properties?.address;

    if (result.properties) {
      address =
        fullAddress.road ||
        fullAddress.street ||
        fullAddress.neighbourhood ||
        fullAddress.suburb;
      suburb = !address
        ? fullAddress.suburb || fullAddress.neighbourhood
        : undefined;
      city = fullAddress.city || fullAddress.town || fullAddress.municipality;
      state = fullAddress.state;
    }

    return `${address ? address + ', ' : ''}${
      suburb ? suburb + ', ' : ''
    }${city} - ${state}`;
  };

  return (
    <MapControl position={ControlPosition.TOP_RIGHT}>
      <div className='bg-white p-4 m-2 border-gray-400 rounded shadow opacity-90'>
        {/* <input
          type='text'
          ref={inputRef}
          placeholder='Ex.: "São mateus", "Centro"'
          className='md:w-96 xs:w-80 w-60 h-12 px-4 text-black font-bold duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg'
        /> */}
        <div>
          <input
            type='text'
            ref={nominatimRef}
            placeholder='Busca no Nominatim'
            onChange={(e) => setSearchText(e.target.value)}
            className='placeholder:font-light md:w-96 xs:w-80 w-60 h-12 px-4 text-black text-base font-medium duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg'
          />
          <ul className=''>
            {results.features?.map((result, index) => (
              <li
                key={index}
                onClick={() => drawResultPolygon(result)}
                className='flex h-10 md:w-96 xs:w-80 w-60 p-4 items-center rounded-md hover:bg-emerald-100 cursor-pointer'
              >
                <p className='text-ellipsis whitespace-nowrap overflow-hidden text-base'>
                  {formatResults(result)}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {/* <button
          className='bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow'
          onClick={searchNominatim}
        >
          Buscar
        </button> */}
      </div>
    </MapControl>
  );
};

export default SearchBox;
