import { useEffect, useState } from 'react';
import '../../App.css';
import {
  APIProvider,
  AdvancedMarker,
  ControlPosition,
  Map,
  MapControl,
  useApiIsLoaded,
  useMap,
  useMapsLibrary,
  withGoogleMaps,
} from '@vis.gl/react-google-maps';

import house from '../../media/casa.png';
import apartment from '../../media/apartamento.png';
import { resultPolygonConfig, circleConfig } from '../../utils/shapes';

import { trees } from '../../trees';
import DrawingButtons from './DrawingButtons';
import SearchBox from './SearchBox';

import { serverUrl } from '../../config/config.js';

const DrawingMap = () => {
  const drawer = useMapsLibrary('drawing');
  const geometry = useMapsLibrary('geometry');
  const polygons = useMapsLibrary('maps');
  // const places = useMapsLibrary('places');
  const map = useMap();
  const apiIsLoaded = useApiIsLoaded();
  let drawingManager = null;
  const [drawerInstance, setDrawerInstance] = useState(null);
  const [polygon, setPolygon] = useState(null);

  useEffect(() => {
    if (!map || !apiIsLoaded || !drawer || !polygons) return;
    // const newSearchBox = new places.SearchBox();
    // console.log('places:', searchBox);

    drawingManager = new drawer.DrawingManager({
      drawingMode: polygon ? null : drawer.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: [drawer.OverlayType.POLYGON, drawer.OverlayType.POLYLINE],
      },
      polygonOptions: {
        fillColor: 'aquamarine',
        fillOpacity: 0.5,
        strokeWeight: 3,
        editable: true,
        draggable: true,
        zIndex: 1,
      },
      // polygonEvents: [
      //   'click',
      //   'dblclick',
      //   'dragstart',
      //   'drag',
      //   'dragend',
      //   'mouseover',
      //   'mouseout',
      // ],
    });

    drawingManager.setMap(map);
    drawingManager.addListener('polygoncomplete', (polygon) => {
      setPolygon(polygon);
      drawingManager.setDrawingMode(null);
    });

    drawingManager.addListener('overlaycomplete', (event) => {
      console.log('Overlay Complete:', event);
    });

    drawingManager.addListener('set_at', (event) => {
      console.log('Vertex Added:', event);
    });

    drawingManager.addListener('click', (event) => {
      console.log('Map Clicked:', event);
    });

    console.log('Drawing Manager:', drawingManager.panWhileDrawing);
    setDrawerInstance(drawingManager);
    loadPolygons(map);
  }, [map, apiIsLoaded, drawer, polygons, polygon]);

  const drawResultPolygon = (result) => {
    console.log('Result:', result);
    if (!result || !result.geometry) return;
    // check if the result is a polygon and store the first result that is a polygon
    const mainResult = result.geometry.type === 'Polygon' && result;
    console.log('Main Result:', mainResult);

    // check if the result is a LineString and draw a circle around the first result that is a LineString with a radius of 500m and a marker on the point
    if (result.geometry.type === 'LineString') {
      const image = document.createElement('img');
      image.src = house;
      image.width = 22;
      image.height = 22;

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: result.geometry.coordinates[0][1],
          lng: result.geometry.coordinates[0][0],
        },
        map: map,
        // content: image,
      });
      marker.setMap(map);
      const circle = new polygons.Circle({
        ...circleConfig,
        center: {
          lat: result.geometry.coordinates[0][1],
          lng: result.geometry.coordinates[0][0],
        },
        radius: 500,
        map: map,
      });
      circle.setMap(map);

      return;
    }
    // check if the result is a Point and draw a circle around it with a radius of 1000m and a marker on the point
    if (result.geometry.type === 'Point') {
      const image = document.createElement('img');
      image.src = apartment;
      image.width = 22;
      image.height = 22;

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: result.geometry.coordinates[1],
          lng: result.geometry.coordinates[0],
        },
        map: map,
        // content: image,
      });
      marker.setMap(map);
      const circle = new polygons.Circle({
        ...circleConfig,
        center: {
          lat: result.geometry.coordinates[1],
          lng: result.geometry.coordinates[0],
        },
        radius: 500,
        map: map,
      });
      circle.setMap(map);

      return;
    }
    if (!mainResult) return;
    // check if the polygon has coordinates
    if (!mainResult.geometry.coordinates[0]) return;

    // create a new polygon with the coordinates of the result
    const newPolygon = new polygons.Polygon({
      ...resultPolygonConfig,
      paths: mainResult.geometry.coordinates[0].map((coord) => ({
        lat: coord[1],
        lng: coord[0],
      })),
      map: map,
    });
    newPolygon.setMap(map);
  };

  const savePolygon = async (polygon) => {
    if (!polygon) return;

    const path = polygon.getPath();

    const encodedPath = geometry.encoding.encodePath(polygon.getPath());
    const newPolygon = {
      path: encodedPath,
      userId: '1234567890',
      location: {
        type: 'Polygon',
        coordinates: [
          // I need also to push the first point to the end of the array
          path
            .getArray()
            .map((latLng) => [latLng.lng(), latLng.lat()])
            .concat([[path.getAt(0).lng(), path.getAt(0).lat()]]),
          // path.getArray().map((latLng) => [latLng.lng(), latLng.lat()]),
        ],
      },
    };
    console.log('New Polygon:', newPolygon);
    fetch(`${serverUrl}/polygons`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newPolygon),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadPolygons = async (map) => {
    fetch(`${serverUrl}/polygons`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('Polígonos encontrados: ', data.length);
        data.forEach((polygon) => {
          const decodedPath = geometry.encoding.decodePath(polygon.path);
          const newPolygon = new polygons.Polygon({
            path: decodedPath,
            map: map,
            fillColor: '#C1D7E4',
            fillOpacity: 0.5,
            strokeWeight: 3,
            strokeColor: '#238DBF',
            editable: true,
            draggable: true,
          });
          newPolygon.setMap(map);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() => {
    //   console.log('done');
    //   return;
    // });
  };

  return (
    <>
      <div style={{ height: '100vh', width: '100%' }}>
        <Map
          defaultZoom={13}
          minZoom={5}
          maxZoom={18}
          defaultCenter={{ lat: -21.771308908010184, lng: -43.31966043040564 }}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          mapId={process.env.REACT_APP_MAP_ID}
        >
          <DrawingButtons polygon={polygon} setPolygon={setPolygon} />
          <SearchBox
            polygon={polygon}
            setPolygon={setPolygon}
            map={map}
            drawResultPolygon={drawResultPolygon}
          />
          {trees.map((tree) => (
            <AdvancedMarker
              key={tree[0] + tree[1]}
              position={{ lat: tree[1], lng: tree[2] }}
            >
              <div>
                <img src={house} width={22} height={22} />
              </div>
            </AdvancedMarker>
          ))}
        </Map>
      </div>
    </>
  );
};

export default DrawingMap;
