export const circleConfig = {
  fillColor: '#C1D7E4',
  fillOpacity: 0.5,
  scale: 4,
  strokeColor: '#238DBF',
  strokeWeight: 1,
  strokeOpacity: 1,
  map: '',
  center: '',
  radius: '',
};

export const polygonConfig = {
  fillColor: '#C1D7E4',
  fillOpacity: 0.5,
  strokeWeight: 3,
  strokeColor: '#238DBF',
  editable: true,
  draggable: true,
  map: '',
  paths: '',
};

export const resultPolygonConfig = {
  fillColor: '#C1D7E4',
  fillOpacity: 0.5,
  strokeWeight: 3,
  strokeColor: '#238DBF',
  editable: false,
  draggable: false,
  map: '',
  paths: '',
};

export const drawingPolygonConfig = {
  color: '#10B981',
  weight: 3,
  opacity: 1,
  fill: true,
  fillColor: '#10B981',
  fillOpacity: 0.5,
  clickable: true,
};
