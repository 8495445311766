import { toast } from 'react-toastify';
import useSWR from 'swr';
import { getValidToken } from './auth';
import { serverUrl } from '../config/config';
const polyUtil = require('polyline-encoded');

// Create a post method to fetch the properties list within a given area
export const getPropertiesInArea = async (
  searchArea,
  type,
  filters,
  action
) => {
  console.log('Filters: ', filters);

  if (type === 'Polygon') {
    console.log('searchArea: ', searchArea);
    const formattedObject = {
      type: 'Polygon',
      coordinates: polyUtil.encode(searchArea),
      filters,
      action,
    };
    try {
      const response = await fetch(`${serverUrl}/properties/search`, {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(formattedObject),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
    }
  }
  if (type === 'Circle') {
    const formattedObject = {
      type: 'Circle',
      coordinates: [searchArea[0], searchArea[1]],
      filters,
      action,
    };
    console.log('formattedObject: ', formattedObject);
    try {
      const response = await fetch(`${serverUrl}/properties/search`, {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(formattedObject),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
    }
  }
};

// Create a post method to save the drawn area
export const addProperty = async (formData) => {
  try {
    const response = await fetch(`${serverUrl}/properties/new-property`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });
    if (!response.ok) {
      response.json().then((data) => {
        toast.error(data.message);
      });

      return;
    }
    // console.log('response: ', response);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the properties list
export const getPropertiesList = async () => {
  try {
    const response = await fetch(`${serverUrl}/properties`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the property by id
export const getPropertyById = async (id) => {
  try {
    const response = await fetch(`${serverUrl}/properties/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const usePropertiesList = (id) => {
  const { data, error, isLoading } = useSWR(
    `propertiesList`,
    getPropertiesList
  );

  return {
    propertiesList: data,
    isLoading,
    isError: error,
  };
};
