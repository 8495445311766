import { Button, Divider, Drawer, TextField, Typography } from '@mui/material';
import { Cross1Icon } from '@radix-ui/react-icons';
import { IconButton, Separator } from '@radix-ui/themes';
import React, { useEffect, useState } from 'react';

import logo from '../../media/real-estate.png';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import MyAccount from './MyAccount';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';

const LoginDrawer = ({ loginDrawer, setLoginDrawer }) => {
  const [login, setLogin] = useState(true);
  const { userId } = useSelector((state) => state.loginValues);
  const { loginDrawerOpen } = useSelector((state) => state.drawerValues);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   userId && console.log('LoginDrawer', userId);
  // }, [userId]);

  return (
    <Drawer
      open={loginDrawerOpen}
      onClose={() => dispatch(drawerValuesActions.setLoginDrawer(false))}
      anchor='right'
    >
      <div className='flex flex-col w-80 h-full'>
        <div className='flex justify-between p-4'>
          <img src={logo} alt='logo' className='w-8 h-8' />
          <h1 className='font-semibold text-xl '>Rentia</h1>
          <IconButton
            className='hover:cursor-pointer'
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(false))}
          >
            <Cross1Icon />
          </IconButton>
        </div>
        <Divider my='3' size='4' />
        <div className='grow'>
          {userId ? (
            <MyAccount />
          ) : login ? (
            <LoginForm setLogin={setLogin} />
          ) : (
            <RegisterForm setLogin={setLogin} />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default LoginDrawer;
