import {
  ArrowBackOutlined,
  BedOutlined,
  Camera,
  CameraAltOutlined,
  DirectionsCarFilledOutlined,
  FavoriteBorderOutlined,
  ShareOutlined,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import React from 'react';
import Carousel from 'react-multi-carousel';
import { useSelector } from 'react-redux';
import housePlaceholder from '../../media/housePlaceholder.webp';

const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { min: 1024, max: 2048 },
    items: 3,
    partialVisibilityGutter: 40,
  },
};

const PropertyDetailsDialog = ({
  propertyDetailsDrawer,
  handleClosePropertyDetailsDrawer,
  isExtraSmallDevice,
}) => {
  const { selectedProperty } = useSelector((state) => state.searchValues);
  return (
    <div>
      {selectedProperty && (
        <Dialog
          fullScreen
          open={propertyDetailsDrawer}
          onClose={handleClosePropertyDetailsDrawer}
        >
          <div className='flex justify-between items-center'>
            <DialogTitle>Detalhes do Imóvel</DialogTitle>
            <div className='mr-4'>
              <Button
                size='small'
                variant='contained'
                startIcon={<ArrowBackOutlined />}
                onClick={handleClosePropertyDetailsDrawer}
                color='success'
              >
                Voltar
              </Button>
            </div>
          </div>
          <Divider />
          <div>
            <DialogContent>
              <div className='flex flex-col w-full gap-4'>
                <div className='relative'>
                  <Carousel
                    responsive={responsive}
                    infinite={true}
                    slidesToSlide={1}
                    className='w-full'
                    // containerClass='container'
                    swipeable
                    draggable
                    autoPlay={true}
                    partialVisible={true}
                    // centerMode={true}
                    keyBoardControl={true}
                    arrows={true}
                  >
                    {selectedProperty &&
                      [...selectedProperty.images]
                        .sort((a, b) => {
                          if (a.isCover) return -1;
                          if (b.isCover) return 1;
                        })
                        .map((image) => (
                          <div className='flex mx-2'>
                            <img
                              key={image.name}
                              src={image.url || housePlaceholder}
                              alt='property'
                              className='w-fit h-96 object-fill'
                            />
                          </div>
                        ))}
                  </Carousel>
                  <div className='absolute top-4 left-10'>
                    <Button
                      startIcon={<CameraAltOutlined />}
                      variant='contained'
                    >
                      {`${selectedProperty.images.length} fotos`}
                    </Button>
                  </div>
                </div>
                <div className='flex flex-col w-full'>
                  <div className='flex justify-between'>
                    <div>
                      <h1 className='text-xl font-bold'>
                        {selectedProperty.description.slice(0, 50)}
                      </h1>
                    </div>
                    <div className='flex gap-2'>
                      <IconButton>
                        <FavoriteBorderOutlined />
                      </IconButton>
                      <IconButton>
                        <ShareOutlined />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full'>
                  <h1 className='text-xl font-bold'>Preço</h1>
                  <div className='flex justify-between'>
                    <h1 className='text-2xl font-bold text-emerald-700'>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(
                        selectedProperty.price +
                          selectedProperty.monthlyTax +
                          selectedProperty.iptu / 12
                      )}
                    </h1>
                    <p>{`Adicionado em: ${new Date(
                      selectedProperty.dateCreate
                    ).toLocaleDateString()}`}</p>
                  </div>
                  <div className='flex flex-col'>
                    <h1>{`Condomínio: ${new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(selectedProperty.monthlyTax)}`}</h1>
                    <h1>{`IPTU: ${new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(selectedProperty.iptu)}`}</h1>
                  </div>
                </div>
                <div className='flex w-full justify-between'>
                  <div className='flex flex-col'>
                    <h1>Tipo de imóvel</h1>
                    <h1>{selectedProperty.type}</h1>
                  </div>
                  <div className='flex flex-col'>
                    <h1>Área</h1>
                    <div className='flex items-center gap-1'>
                      <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                      <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.area} m²`}</p>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <h1>Quartos</h1>
                    <div className='flex items-center gap-1'>
                      <BedOutlined className='w-6 h-6 text-gray-500' />
                      <p className='text-sm text-gray-500 font-medium'>{`${
                        selectedProperty.bedrooms
                      } ${!isExtraSmallDevice ? 'quartos' : ''}`}</p>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <h1>Banheiros</h1>
                    <div className='flex items-center gap-1'>
                      <ShowerOutlined className='w-6 h-6 text-gray-500' />
                      <p className='text-sm text-gray-500 font-medium'>{`${
                        selectedProperty.bathrooms
                      } ${!isExtraSmallDevice ? 'banheiros' : ''}`}</p>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <h1>Vagas</h1>
                    <div className='flex items-center gap-1'>
                      <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                      <p className='text-sm text-gray-500 font-medium'>{`${
                        selectedProperty.parkingSlots
                      } ${!isExtraSmallDevice ? 'vagas' : ''}`}</p>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-full gap-2'>
                  <h1 className='font-semibold'>Características do imóvel</h1>
                  <ol>
                    <li>{`Fiador: ${
                      selectedProperty.guarantor ? 'Sim' : 'Não'
                    }`}</li>
                    <li>{`Caução: ${
                      selectedProperty.deposit ? 'Sim' : 'Não'
                    }`}</li>
                    <li>{`Portaria 24h: ${
                      selectedProperty.concierge ? 'Sim' : 'Não'
                    }`}</li>
                    <li>{`Aceita PET?: ${
                      selectedProperty.petFriendly ? 'Sim' : 'Não'
                    }`}</li>
                  </ol>
                </div>
                <div className='flex flex-col w-full gap-2'>
                  <h1 className='font-semibold'>Descrição completa</h1>
                  <p>{selectedProperty.description}</p>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default PropertyDetailsDialog;
