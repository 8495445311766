import React, { useEffect, useRef, useState } from 'react';

import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getPropertiesInArea,
  getPropertyById,
} from '../../services/propertyService';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import highlightedMarker from '../../media/icons/highlighted-marker.svg';
import defaultMarker from '../../media/icons/default-marker.svg';
import markerShadow from '../../media/icons/marker-shadow.png';
import Autocomplete from '../../autocomplete-master/dist/js/autocomplete.esm.js';
import { searchAddress } from '../../services/mapService.js';
import { formatResults } from '../../helpers/formattingFunctions.js';
import tuneIcon from '../../media/icons/tuneIcon.svg';
import bellIcon from '../../media/icons/bellIcon.svg';
import polygonIcon from '../../media/icons/polygonIcon.svg';
import { useMediaQuery } from '@uidotdev/usehooks';
import { drawingAreasValuesActions } from '../../store/drawingAreasValues.js';
import SearchFilters from './SearchFilters.js';
import SelectedPropertyDrawer from '../Layout/SelectedPropertyDrawer.js';
import { searchValuesActions } from '../../store/searchValues.js';
import { createAlert } from '../../services/alertService.js';

import { renderToString } from 'react-dom/server';
const polyUtil = require('polyline-encoded');

const SearchInput = () => {
  return (
    <Tooltip title='Rua, Bairro, Cidade'>
      <div className='auto-search-wrapper'>
        <input
          className='rounded-sm shadow-xl bg-slate-100 hover:bg-slate-50 duration-300'
          type='text'
          id='search-input'
          placeholder='Pesquisar'
          name='address'
          autocomplete='street-address'
        />
      </div>
    </Tooltip>
  );
};

const SearchListItem = ({ formatResults, results }) => {
  return (
    <li className=' hover:bg-emerald-500 duration-200'>
      {formatResults(results)}
    </li>
  );
};

const FiltersButton = () => {
  return (
    <button className='flex gap-2 p-1 md:p-2 md:mt-2 bg-white rounded-md shadow-lg text-xs  font-semibold mr-0 md:mr-4 px-3 hover:scale-105 hover:bg-emerald-500 border border-slate-500 active:bg-emerald-500 duration-500'>
      <img src={tuneIcon} className='w-4 h-4' />
      Filtros
    </button>
  );
};

const BackToDrawingModeButton = () => {
  return (
    <button className='flex gap-2 p-1 md:p-2 md:mt-2 bg-white rounded-md shadow-lg text-xs  font-semibold mr-0 md:mr-4 px-3 hover:scale-105 hover:bg-emerald-500 border border-slate-500 active:bg-emerald-500 duration-500'>
      <img src={polygonIcon} className='w-4 h-4' />
      Desenhar Área
    </button>
  );
};

const CreateAlertButton = () => {
  return (
    <button className='flex gap-2 p-1 md:p-2 md:mt-2 bg-white rounded-md shadow-lg text-xs  font-semibold mr-0 md:mr-4 px-3 hover:scale-105 hover:bg-emerald-500 border border-slate-500 active:bg-emerald-500 duration-500'>
      <img src={bellIcon} className='w-4 h-4' />
      Criar Alerta
    </button>
  );
};

const ActionButtons = ({ searchParams }) => {
  return (
    <div className='flex flex-col gap-2 p-1 md:p-2 md:mt-2 bg-white rounded-md shadow-lg text-xs  font-semibold mr-0 md:mr-4 px-3 hover:scale-105 hover:bg-emerald-500 border border-slate-500 active:bg-emerald-500 duration-500'>
      <div className='flex gap-2'>
        <label className='w-14' htmlFor='rent'>
          Alugar
        </label>
        <input
          type='radio'
          id='rent'
          name='action'
          value='rent'
          defaultChecked={
            searchParams.get('action') === 'rent' || !searchParams.get('action')
          }
        />
      </div>
      <div className='flex gap-2'>
        <label className='w-14' htmlFor='sale'>
          Comprar
        </label>
        <input
          type='radio'
          id='sale'
          name='action'
          value='sale'
          defaultChecked={searchParams.get('action') === 'sale'}
        />
      </div>
    </div>
  );
};

const WrapperSearchResultsComponent = () => {
  const navigate = useNavigate();
  const map = useMap();
  let markers = L.markerClusterGroup();
  const [searchParams, setSearchParams] = useSearchParams();
  const { drawnArea, selectedDrawnArea } = useSelector(
    (state) => state.drawingAreasValues
  );
  const { searchMode, selectedResult, selectedProperty } = useSelector(
    (state) => state.searchValues
  );
  const dispatch = useDispatch();
  const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);
  const [isPropertyDrawerOpen, setIsPropertyDrawerOpen] = useState(false);
  const [isCreateAlertDialogOpen, setIsCreateAlertDialogOpen] = useState(false);
  // const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState([]);
  // const [selectedResult, setSelectedResult] = useState(null);

  //   const searchInputDiv = `
  //   <div className="auto-search-wrapper rounded-sm">
  //   <input className="rounded-sm" type="text" id="search-input" placeholder="Pesquisar" />
  // </div>
  // `;

  const highlightedMarkerIcon = L.icon({
    iconUrl: highlightedMarker,
    shadowUrl: markerShadow,
    iconSize: [20, 30],
    shadowSize: [33, 30],
    iconAnchor: [12, 41],
    popupAnchor: [-2, -34],
    tooltipAnchor: [16, -28],
  });

  const defaultMarkerIcon = L.icon({
    iconUrl: defaultMarker,
    shadowUrl: markerShadow,
    iconSize: [20, 30],
    shadowSize: [33, 30],
    iconAnchor: [12, 41],
    popupAnchor: [-2, -34],
    tooltipAnchor: [16, -28],
  });

  const isExtraSmallDevice = useMediaQuery(
    'only screen and (max-width : 350px)'
  );

  const onSubmit = (formData) => {
    console.log(formData);

    // if (!drawnArea && !selectedDrawnArea && !selectedResult) {
    //   toast.error('Desenhe uma área no mapa ou selecione um endereço');
    //   return;
    // }
    // if (drawnArea) {
    //   toast.success('Drawn Area is set');
    //   return;
    // }
    // if (selectedDrawnArea) {
    //   toast.success('SelectedDrawn Area is set');
    //   return;
    // }
    // if (selectedResult) {
    //   toast.success('Location is set');
    //   handleSelectedInput(selectedResult, formData);
    // }
    setSearchParams((old) => {
      for (const key in formData) {
        if (formData[key] === 'any' || formData[key] === '') {
          old.delete(key);
        } else {
          old.set(key, formData[key]);
        }
      }
      return old;
    });
    handleSearchProperties(formData);
  };

  const clearLayers = () => {
    map.eachLayer((layer) => {
      if (
        layer instanceof L.Circle ||
        layer instanceof L.Polyline ||
        layer instanceof L.Polygon ||
        layer instanceof L.Marker ||
        layer instanceof L.MarkerClusterGroup
      ) {
        map.removeLayer(layer);
      }
    });
  };

  const handleCloseFiltersDialog = () => {
    setIsFiltersDialogOpen(false);
  };

  const handleCloseCreateAlertDialog = () => {
    setIsCreateAlertDialogOpen(false);
  };

  const handleCreateAlert = () => {
    if (!selectedResult && !drawnArea && !selectedDrawnArea) {
      toast.error('Nenhuma área ou endereço foi selecionado.');
      setIsCreateAlertDialogOpen(false);
      return;
    }

    const filters = [];

    searchParams.entries().forEach((item) => {
      filters.push({ key: item[0], value: item[1] });
    });

    if (searchMode === 'selectedPoint') {
      const alert = {
        coordinates: [
          selectedResult.geometry.coordinates[1],
          selectedResult.geometry.coordinates[0],
        ],
        filters,
        geometry: 'Point',
        searchRadius: searchParams.get('searchRadius'),
      };
      createAlert(alert)
        .then((data) => {
          console.log(data);
          toast.success('Alerta criado com sucesso');
          setIsCreateAlertDialogOpen(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Erro ao criar alerta');
        });
    }

    // if (searchMode === 'selectedLineString') {
    //   const alert = {
    //     type: 'LineString',
    //     coordinates: selectedResult.geometry.coordinates,
    //   };
    //   createAlert(alert)
    //     .then((data) => {
    //       console.log(data);
    //       toast.success('Alerta criado com sucesso');
    //       setIsCreateAlertDialogOpen(false);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       toast.error('Erro ao criar alerta');
    //     });
    // }

    // if (searchMode === 'selectedPolygon') {
    //   const alert = {
    //     type: 'Polygon',
    //     coordinates: selectedResult.geometry.coordinates[0],
    //   };
    //   createAlert(alert)
    //     .then((data) => {
    //       console.log(data);
    //       toast.success('Alerta criado com sucesso');
    //       setIsCreateAlertDialogOpen(false);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       toast.error('Erro ao criar alerta');
    //     });
    // }

    // if (searchMode === 'drawnArea' || searchMode === 'selectedDrawnArea') {
    //   const alert = {
    //     type: 'Polygon',
    //     coordinates: searchMode === 'drawnArea' ? drawnArea : selectedDrawnArea,
    //   };
    //   createAlert(alert)
    //     .then((data) => {
    //       console.log(data);
    //       toast.success('Alerta criado com sucesso');
    //       setIsCreateAlertDialogOpen(false);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       toast.error('Erro ao criar alerta');
    //     });
    // }

    console.log('filters:', filters);
  };

  // const handleSelectedInput = (object, formData) => {
  //   // remove drawn layers from the map before adding new ones
  //   markers.clearLayers();
  //   clearLayers();
  //   if (object.geometry.type === 'Point') {
  //     console.log('Its a point');
  //     // draw a circle with the radius of 100 meters around the point
  //     const circle = L.circle(
  //       [object.geometry.coordinates[1], object.geometry.coordinates[0]],
  //       {
  //         //   color: 'red',
  //         //   fillColor: '#f03',
  //         //   fillOpacity: 0.5,
  //         radius: formData.searchRadius * 1000,
  //       }
  //     ).addTo(map);
  //     map.flyToBounds(circle.getBounds());
  //     getPropertiesInArea(object.geometry.coordinates, 'Circle', formData)
  //       .then((data) => {
  //         console.log(typeof data);
  //         if (data && data.length > 0) {
  //           toast.success(`${data.length} imóveis encontrados`);
  //           const markersArray = [];
  //           data.forEach((property) => {
  //             const marker = L.marker(
  //               [
  //                 property.location.coordinates[1],
  //                 property.location.coordinates[0],
  //               ],
  //               {
  //                 icon: highlightedMarkerIcon,
  //               }
  //             );
  //             marker.on('click', (e) => {
  //               map.panTo({
  //                 lat: e.latlng.lat,
  //                 lng: e.latlng.lng,
  //               });

  //               setSelectedMarker((old) => {
  //                 let newMarkerArray = [...old];
  //                 if (newMarkerArray.length > 1) {
  //                   newMarkerArray.push(marker);
  //                   newMarkerArray.shift();
  //                 } else {
  //                   newMarkerArray.push(marker);
  //                 }
  //                 return newMarkerArray;
  //               });
  //               // console.log('Marker clicked:', property);
  //               // console.log('Marker Event:', e);
  //               setSelectedProperty(property);
  //               setIsPropertyDrawerOpen(true);
  //             });

  //             marker
  //               .bindTooltip(
  //                 new Intl.NumberFormat('pt-BR', {
  //                   style: 'currency',
  //                   currency: 'BRL',
  //                 }).format(property.price),
  //                 {
  //                   permanent: true,
  //                   direction: 'top',
  //                   offset: [-17, -20],
  //                   className: 'tooltip',
  //                 }
  //               )
  //               .openTooltip();
  //             markersArray.push(marker);
  //           });
  //           markers.addLayers(markersArray);
  //           map.addLayer(markers);
  //           setIsFiltersDialogOpen(false);
  //         } else {
  //           toast.error('Nenhum imóvel encontrado');
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toast.error('Erro ao buscar imóveis');
  //       });
  //   }
  //   if (object.geometry.type === 'LineString') {
  //     console.log('Its a line string');
  //     const polyline = L.polyline(
  //       object.geometry.coordinates.map((coord) => [coord[1], coord[0]]),
  //       {
  //         color: 'red',
  //         opacity: 0.5,
  //       }
  //     ).addTo(map);
  //     const center = polyline.getCenter();
  //     console.log('Center:', center);
  //     const circle = L.circle(center, {
  //       //   color: 'red',
  //       //   fillColor: '#f03',
  //       //   fillOpacity: 0.5,
  //       radius: formData.searchRadius * 1000,
  //     }).addTo(map);
  //     // map.flyToBounds(circle.getBounds());
  //     map.flyToBounds(polyline.getBounds());
  //     getPropertiesInArea([center.lng, center.lat], 'Circle', formData)
  //       .then((data) => {
  //         console.log(typeof data);
  //         if (data && data.length > 0) {
  //           toast.success(`${data.length} imóveis encontrados`);
  //           const markersArray = [];
  //           data.forEach((property) => {
  //             const marker = L.marker(
  //               [
  //                 property.location.coordinates[1],
  //                 property.location.coordinates[0],
  //               ],
  //               {
  //                 icon: highlightedMarkerIcon,
  //               }
  //             );
  //             marker.on('click', (e) => {
  //               map.panTo({
  //                 lat: e.latlng.lat,
  //                 lng: e.latlng.lng,
  //               });

  //               setSelectedMarker((old) => {
  //                 let newMarkerArray = [...old];
  //                 if (newMarkerArray.length > 1) {
  //                   newMarkerArray.push(marker);
  //                   newMarkerArray.shift();
  //                 } else {
  //                   newMarkerArray.push(marker);
  //                 }
  //                 return newMarkerArray;
  //               });
  //               // console.log('Marker clicked:', property);
  //               // console.log('Marker Event:', e);
  //               setSelectedProperty(property);
  //               setIsPropertyDrawerOpen(true);
  //             });

  //             marker
  //               .bindTooltip(
  //                 new Intl.NumberFormat('pt-BR', {
  //                   style: 'currency',
  //                   currency: 'BRL',
  //                 }).format(property.price),
  //                 {
  //                   permanent: true,
  //                   direction: 'top',
  //                   offset: [-17, -20],
  //                   className: 'tooltip',
  //                 }
  //               )
  //               .openTooltip();
  //             markersArray.push(marker);
  //           });
  //           markers.addLayers(markersArray);
  //           map.addLayer(markers);
  //           setIsFiltersDialogOpen(false);
  //         } else {
  //           toast.error('Nenhum imóvel encontrado');
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toast.error('Erro ao buscar imóveis');
  //       });
  //   }
  //   if (object.geometry.type === 'Polygon') {
  //     console.log('Its a polygon');
  //     const polygon = L.polygon(
  //       object.geometry.coordinates[0].map((coord) => [coord[1], coord[0]])
  //     ).addTo(map);
  //     map.flyToBounds(polygon.getBounds());
  //     getPropertiesInArea(object.geometry.coordinates[0], 'Polygon', formData)
  //       .then((data) => {
  //         // console.log(data);
  //         if (data.length > 0) {
  //           toast.success(`${data.length} imóveis encontrados`);
  //           const markersArray = [];
  //           data.forEach((property) => {
  //             const marker = L.marker(
  //               [
  //                 property.location.coordinates[1],
  //                 property.location.coordinates[0],
  //               ],
  //               {
  //                 icon: highlightedMarkerIcon,
  //               }
  //             );
  //             marker.on('click', (e) => {
  //               map.panTo({
  //                 lat: e.latlng.lat,
  //                 lng: e.latlng.lng,
  //               });

  //               setSelectedMarker((old) => {
  //                 let newMarkerArray = [...old];
  //                 if (newMarkerArray.length > 1) {
  //                   newMarkerArray.push(marker);
  //                   newMarkerArray.shift();
  //                 } else {
  //                   newMarkerArray.push(marker);
  //                 }
  //                 return newMarkerArray;
  //               });
  //               // console.log('Marker clicked:', property);
  //               // console.log('Marker Event:', e);
  //               setSelectedProperty(property);
  //               setIsPropertyDrawerOpen(true);
  //             });

  //             marker
  //               .bindTooltip(
  //                 new Intl.NumberFormat('pt-BR', {
  //                   style: 'currency',
  //                   currency: 'BRL',
  //                 }).format(property.price),
  //                 {
  //                   permanent: true,
  //                   direction: 'top',
  //                   offset: [-17, -20],
  //                   className: 'tooltip',
  //                 }
  //               )
  //               .openTooltip();
  //             markersArray.push(marker);
  //           });
  //           markers.addLayers(markersArray);
  //           map.addLayer(markers);
  //           setIsFiltersDialogOpen(false);
  //         } else {
  //           toast.error('Nenhum imóvel encontrado');
  //         }
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         toast.error('Erro ao buscar imóveis');
  //       });
  //   }
  // };

  const handleSearchProperties = (formData) => {
    console.log('handleSearchProperties');
    let newCoordinates;
    let geometryType;

    // remove drawn layers from the map before adding new ones
    markers.clearLayers();
    clearLayers();

    switch (searchMode) {
      case 'drawnArea':
        if (!drawnArea) {
          toast.error('Erro com a drawnArea');
          return;
        } else {
          const polygon = new L.Polygon(drawnArea);
          map.addLayer(polygon);
          map.flyToBounds(polygon.getBounds(), {
            padding: [50, 50],
            animate: true,
          });
          newCoordinates = drawnArea.map((coord) => [coord[1], coord[0]]);
          geometryType = 'Polygon';
        }
        break;
      case 'selectedDrawnArea':
        if (!selectedDrawnArea) {
          toast.error('Erro com a selectedDrawnArea');
          return;
        } else {
          const polygon = new L.Polygon(selectedDrawnArea);
          map.addLayer(polygon);
          map.flyToBounds(polygon.getBounds(), {
            padding: [50, 50],
            animate: true,
          });
          newCoordinates = selectedDrawnArea;
          geometryType = 'Polygon';
        }
        break;
      case 'selectedPoint':
        if (!selectedResult) {
          toast.error('Erro com a selectedPoint');
          return;
        } else {
          const circle = L.circle(
            [
              selectedResult.geometry.coordinates[1],
              selectedResult.geometry.coordinates[0],
            ],
            {
              //   color: 'red',
              //   fillColor: '#f03',
              //   fillOpacity: 0.5,
              radius: formData.searchRadius * 1000,
            }
          ).addTo(map);
          map.flyToBounds(circle.getBounds());
          newCoordinates = selectedResult.geometry.coordinates;
          geometryType = 'Circle';
        }
        break;
      case 'selectedLineString':
        if (!selectedResult) {
          toast.error('Erro com a selectedLineString');
          return;
        } else {
          const polyline = L.polyline(
            selectedResult.geometry.coordinates.map((coord) => [
              coord[1],
              coord[0],
            ]),
            {
              color: 'red',
              opacity: 0.5,
            }
          ).addTo(map);
          const center = polyline.getCenter();
          console.log('Center:', center);
          const circle = L.circle(center, {
            //   color: 'red',
            //   fillColor: '#f03',
            //   fillOpacity: 0.5,
            radius: formData.searchRadius * 1000,
          }).addTo(map);
          // map.flyToBounds(circle.getBounds());
          map.flyToBounds(circle.getBounds());
          newCoordinates = [center.lng, center.lat];
          geometryType = 'Circle';
        }
        break;
      case 'selectedPolygon':
        if (!selectedResult) {
          toast.error('Erro com a selectedPolygon');
          return;
        } else {
          const polygon = L.polygon(
            selectedResult.geometry.coordinates[0].map((coord) => [
              coord[1],
              coord[0],
            ])
          ).addTo(map);
          map.flyToBounds(polygon.getBounds());
          newCoordinates = selectedResult.geometry.coordinates[0];
          geometryType = 'Polygon';
        }
        break;

      default:
        toast.error('Nenhuma área ou endereço foi selecionado.');
        return;
    }
    getPropertiesInArea(
      newCoordinates,
      geometryType,
      formData,
      searchParams.get('action')
    )
      .then((data) => {
        console.log('Found results: ', data);
        if (data.length > 0) {
          toast.success(`${data.length} imóveis encontrados`);
          const markersArray = [];
          data.forEach((property) => {
            const marker = L.marker(
              [
                property.location.coordinates[1],
                property.location.coordinates[0],
              ],
              {
                icon: highlightedMarkerIcon,
              }
            );
            marker.on('click', (e) => {
              map.panTo({
                lat: e.latlng.lat,
                lng: e.latlng.lng,
              });

              setSelectedMarker((old) => {
                let newMarkerArray = [...old];
                if (newMarkerArray.length > 1) {
                  newMarkerArray.push(marker);
                  newMarkerArray.shift();
                } else {
                  newMarkerArray.push(marker);
                }
                return newMarkerArray;
              });
              // console.log('Marker clicked:', property);
              // console.log('Marker Event:', e);
              dispatch(searchValuesActions.setSelectedProperty(property));
              // setSelectedProperty(property);
              setIsPropertyDrawerOpen(true);
            });

            marker
              .bindTooltip(
                new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(property.price),
                {
                  permanent: true,
                  direction: 'top',
                  offset: [-17, -20],
                  className: 'tooltip',
                }
              )
              .openTooltip();
            markersArray.push(marker);
          });
          markers.addLayers(markersArray);
          map.addLayer(markers);
          handleCloseFiltersDialog();
        } else {
          toast.error('Nenhum imóvel encontrado');
          handleCloseFiltersDialog();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error('Erro ao buscar imóveis');
      });
  };

  // Create additional Control placeholders and create all controls, including the autocomplete
  useEffect(() => {
    const addControlPlaceholders = (map) => {
      var corners = map._controlCorners,
        l = 'leaflet-',
        container = map._controlContainer;

      function createCorner(vSide, hSide) {
        var className = l + vSide + ' ' + l + hSide;

        corners[vSide + hSide] = L.DomUtil.create('div', className, container);
      }

      createCorner('top', 'center');
      createCorner('middle', 'center');
      createCorner('middle', 'left');
      createCorner('middle', 'right');
      createCorner('bottom', 'center');
    };
    addControlPlaceholders(map);

    const searchInputControl = L.Control.extend({
      options: {
        position: 'topright',
        className: 'search-input-control',
      },
      // method
      onAdd: function () {
        return this._initialLayout();
      },

      _initialLayout: function () {
        // create button
        const container = L.DomUtil.create(
          'div',
          'auto-search-wrapper ' + this.options.className
        );

        L.DomEvent.disableClickPropagation(container);

        container.innerHTML = renderToString(<SearchInput />);

        return container;
      },
    });
    const searchInput = new searchInputControl();

    //   new L.Control.Search().addTo(map);
    map.addControl(searchInput);
    searchInput.setPosition('topcenter');

    const backToDrawingModeControl = L.Control.extend({
      options: {
        position: 'bottomright',
      },
      // method
      onAdd: function () {
        return this._initialLayout();
      },

      _initialLayout: function () {
        // create button
        const container = L.DomUtil.create('div');

        L.DomEvent.disableClickPropagation(container);

        container.innerHTML = renderToString(<BackToDrawingModeButton />);

        container.addEventListener('click', () => {
          navigate('/desenhar');
        });
        return container;
      },
    });
    const backToDrawingModeControlInstance = new backToDrawingModeControl();

    map.addControl(backToDrawingModeControlInstance);

    const createAlertControl = L.Control.extend({
      options: {
        position: 'bottomright',
      },
      // method
      onAdd: function () {
        return this._initialLayout();
      },

      _initialLayout: function () {
        // create button
        const container = L.DomUtil.create('div');

        L.DomEvent.disableClickPropagation(container);

        container.innerHTML = renderToString(<CreateAlertButton />);

        container.addEventListener('click', () => {
          setIsCreateAlertDialogOpen(true);
        });
        return container;
      },
    });
    const createAlertControlInstance = new createAlertControl();

    map.addControl(createAlertControlInstance);

    // Create a new control with 2 radio buttons to select the action (sale or rent)
    const actionControl = L.Control.extend({
      options: {
        position: 'bottomright',
      },
      // method
      onAdd: function () {
        return this._initialLayout();
      },

      _initialLayout: function () {
        // create button
        const container = L.DomUtil.create('div');
        container.id = 'action-control';

        L.DomEvent.disableClickPropagation(container);

        container.innerHTML = renderToString(
          <ActionButtons searchParams={searchParams} />
        );

        container.addEventListener('click', (e) => {
          if (e.target.id === 'sale') {
            setSearchParams((old) => {
              old.set('action', 'sale');
              return old;
            });
          }
          if (e.target.id === 'rent') {
            setSearchParams((old) => {
              old.set('action', 'rent');
              return old;
            });
          }
        });
        return container;
      },
    });
    const actionControlInstance = new actionControl();

    map.addControl(actionControlInstance);

    const filtersControl = L.Control.extend({
      options: {
        position: 'bottomright',
      },
      // method
      onAdd: function () {
        return this._initialLayout();
      },

      _initialLayout: function () {
        // create button
        const container = L.DomUtil.create('div');

        L.DomEvent.disableClickPropagation(container);

        container.innerHTML = renderToString(<FiltersButton />);

        container.addEventListener('click', () => {
          setIsFiltersDialogOpen(true);
        });
        return container;
      },
    });
    const filtersControlInstance = new filtersControl();

    map.addControl(filtersControlInstance);

    new Autocomplete('search-input', {
      delay: 500,
      insertToInput: true,
      howManyCharacters: 2,
      cache: true,
      removeResultsWhenInputIsEmpty: true,
      ariaLabelClear: 'Apagar texto',

      onSearch: ({ currentValue }) => {
        return new Promise((resolve) => {
          searchAddress(currentValue)
            .then((data) => {
              console.log(data);
              resolve(data);
            })
            .catch((error) => {
              console.error('Erro searchAddress', error);
              toast.error('Tente novamente');
            });
        });
      },
      onResults: ({ currentValue, matches, template, classGroup }) => {
        console.log('onResults', matches);
        return matches === 0
          ? template
          : matches
              .map((results) =>
                renderToString(
                  <SearchListItem
                    formatResults={formatResults}
                    results={results}
                  />
                )
              )
              .join('');
      },

      onSubmit: ({ index, element, object, results }) => {
        console.log('onSubmit', object);
        // clean drawnArea searchParams
        setSearchParams((old) => {
          old.delete('drawnArea');
          return old;
        });

        markers.clearLayers();
        clearLayers();

        if (!object) {
          toast.error('Tente novamente');
          return;
        }
        if (object.geometry.type === 'Point') {
          const circle = L.circle(
            [object.geometry.coordinates[1], object.geometry.coordinates[0]],
            {
              radius: 100,
            }
          ).addTo(map);
          map.on('zoomend', function onZoomEnd() {
            setTimeout(() => {
              setIsFiltersDialogOpen(true);
            }, 500);
            setIsFiltersDialogOpen(true);
            map.off('zoomend', onZoomEnd); // Remove the event listener
          });
          map.flyToBounds(circle.getBounds());
          dispatch(searchValuesActions.setSearchMode('selectedPoint'));
        }
        if (object.geometry.type === 'LineString') {
          const polyline = L.polyline(
            object.geometry.coordinates.map((coord) => [coord[1], coord[0]]),
            {
              color: 'red',
              opacity: 0.5,
            }
          ).addTo(map);
          const center = polyline.getCenter();
          const circle = L.circle(center, {
            radius: 100,
          }).addTo(map);
          map.on('zoomend', function onZoomEnd() {
            setTimeout(() => {
              setIsFiltersDialogOpen(true);
            }, 500);
            setIsFiltersDialogOpen(true);
            map.off('zoomend', onZoomEnd); // Remove the event listener
          });
          map.flyToBounds(circle.getBounds());
          dispatch(searchValuesActions.setSearchMode('selectedLineString'));
        }
        if (object.geometry.type === 'Polygon') {
          if (object.geometry.coordinates[0].length > 1000) {
            toast.warning(
              'Área muito grande. Sua busca pode demorar um pouco.'
            );
          }
          const polygon = L.polygon(
            object.geometry.coordinates[0].map((coord) => [coord[1], coord[0]])
          ).addTo(map);
          map.on('zoomend', function onZoomEnd() {
            setTimeout(() => {
              setIsFiltersDialogOpen(true);
            }, 500);
            setIsFiltersDialogOpen(true);
            map.off('zoomend', onZoomEnd); // Remove the event listener
          });
          map.flyToBounds(polygon.getBounds());
          dispatch(searchValuesActions.setSearchMode('selectedPolygon'));
        }

        if (object.geometry.type === 'MultiPolygon') {
          // normalize to get only the first polygon
          object.geometry.coordinates = object.geometry.coordinates[0];
          if (object.geometry.coordinates[0].length > 1000) {
            toast.warning(
              'Área muito grande. Sua busca pode demorar um pouco.'
            );
          }
          const polygon = L.polygon(
            object.geometry.coordinates[0].map((coord) => [coord[1], coord[0]])
          ).addTo(map);
          map.on('zoomend', function onZoomEnd() {
            setTimeout(() => {
              setIsFiltersDialogOpen(true);
            }, 500);
            setIsFiltersDialogOpen(true);
            map.off('zoomend', onZoomEnd); // Remove the event listener
          });
          map.flyToBounds(polygon.getBounds());
          dispatch(searchValuesActions.setSearchMode('selectedPolygon'));
        }

        // setSelectedResult(object);
        dispatch(searchValuesActions.setSelectedResult(object));
      },
      // Fired when you hover over an item in the results list
      onSelectedItem: ({ index, element, object }) => {
        console.log('onSelectedItem', index, element, object);
      },
      noResults: ({ element, template }) => {
        console.log('noResults', element, template);
        template(`<li>Nenhum resutado encontrado.</li>`);
      },
      onReset: ({ element }) => {
        console.log('onReset', element);
        clearLayers();
        dispatch(searchValuesActions.setSearchMode(''));
        dispatch(searchValuesActions.setSelectedResult(null));
        setIsPropertyDrawerOpen(false);
      },
    });

    return () => {
      clearLayers();
      filtersControlInstance.remove();
      createAlertControlInstance.remove();
      backToDrawingModeControlInstance.remove();
      actionControlInstance.remove();
    };
  }, []);

  // Set the selected marker icon
  useEffect(() => {
    console.log('SelectedMarker', selectedMarker);
    if (selectedMarker.length === 1) {
      selectedMarker[0].setIcon(defaultMarkerIcon);
    }
    if (selectedMarker.length > 1) {
      selectedMarker[0].setIcon(highlightedMarkerIcon);
      selectedMarker[1].setIcon(defaultMarkerIcon);
    }
  }, [selectedMarker]);

  // Set action radio buttons when the navbar link is clicked
  useEffect(() => {
    const actionControlDiv = document.getElementById('action-control');
    actionControlDiv.innerHTML = renderToString(
      <ActionButtons searchParams={searchParams} />
    );
  }, [searchParams.get('action')]);

  // Set mode states and searchParams on mount
  useEffect(() => {
    document.title = 'Resultados da Busca';
    let encodedDrawnArea = '';
    let encodedSelectedDrawnArea = '';

    if (!searchParams.get('action')) {
      setSearchParams((old) => {
        old.set('action', 'rent');
        return old;
      });
    }

    if (searchParams.get('drawnArea')) {
      dispatch(searchValuesActions.setSearchMode('drawnArea'));
      dispatch(
        drawingAreasValuesActions.setDrawnArea(
          polyUtil.decode(searchParams.get('drawnArea'))
        )
      );

      // handleSearchProperties(
      //   markers,
      //   polyUtil.decode(searchParams.get('drawnArea'))
      // );
    } else if (drawnArea) {
      encodedDrawnArea = polyUtil.encode(drawnArea);
      setSearchParams((old) => {
        old.set('drawnArea', encodedDrawnArea);
        return old;
      });
      dispatch(searchValuesActions.setSearchMode('drawnArea'));

      // handleSearchProperties(markers, drawnArea);
    } else if (selectedDrawnArea) {
      encodedSelectedDrawnArea = polyUtil.encode(selectedDrawnArea);
      setSearchParams((old) => {
        old.set('drawnArea', encodedSelectedDrawnArea);
        return old;
      });
      dispatch(searchValuesActions.setSearchMode('selectedDrawnArea'));

      // handleSearchProperties(markers, selectedDrawnArea);
    }

    map.whenReady(() => {
      if (searchParams.get('drawnArea')) {
        // create a polygon with the drawn area from the searchParams

        const polygon = L.polygon(
          polyUtil.decode(searchParams.get('drawnArea'))
        ).addTo(map);
        map.flyToBounds(polygon.getBounds());
        map.on('zoomend', function onZoomEnd() {
          setTimeout(() => {
            setIsFiltersDialogOpen(true);
          }, 500);
          map.off('zoomend', onZoomEnd); // Remove the event listener
        });
      }
    });

    // clean up on unmount
    return () => {
      clearLayers();
      dispatch(searchValuesActions.setSearchMode(''));
      dispatch(searchValuesActions.setSelectedResult(null));
    };
  }, []);

  return (
    <div>
      <Dialog
        open={isFiltersDialogOpen}
        onClose={handleCloseFiltersDialog}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <div className='flex justify-between items-center'>
            <h1 className=''>Filtros</h1>
            <div className='bg-slate-300 p-1 px-4 rounded-md shadow-sm'>
              <h1 className='text-sm font-semibold'>
                {searchParams.get('action') === 'sale' ? 'Comprar' : 'Alugar'}
              </h1>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <SearchFilters onSubmit={onSubmit} />
        </DialogContent>
      </Dialog>
      <SelectedPropertyDrawer
        isPropertyDrawerOpen={isPropertyDrawerOpen}
        setIsPropertyDrawerOpen={setIsPropertyDrawerOpen}
        selectedProperty={selectedProperty}
        isExtraSmallDevice={isExtraSmallDevice}
        handleCreateAlert={handleCreateAlert}
      />
      <Dialog
        open={isCreateAlertDialogOpen}
        onClose={handleCloseCreateAlertDialog}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Criar alerta</DialogTitle>
        <DialogContent dividers={true}>
          <div className='flex flex-col gap-3'>
            <p>
              Ao criar um alerta, você será notificado por e-mail sempre que um
              novo imóvel for anunciado dentro área selecionada.
            </p>
            <p>
              Os filtros aplicados em sua busca serão incluídos ao criar o
              alerta.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
            onClick={handleCloseCreateAlertDialog}
          >
            Cancelar
          </button>
          <button
            className='bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded'
            onClick={handleCreateAlert}
          >
            Criar
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WrapperSearchResultsComponent;
