import React, { useEffect, useRef, useState } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import L from 'leaflet';
import { CloseOutlined, InfoOutlined, MapOutlined } from '@mui/icons-material';
import { searchAddress } from '../../services/mapService';
import { addPropertyFormValuesActions } from '../../store/addPropertyFormValues';
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

const residentialTypes = {
  apartment: 'Apartamento',
  house: 'Casa',
  farm: 'Sítio/Fazenda/Chácara',
  terrain: 'Terreno/Lote',
};
const commercialTypes = {
  commercialHouse: 'Casa',
  office: 'Sala/Conjunto',
  store: 'Ponto Comercial/Loja/Box',
  depot: 'Depósito/Galpão/Armazém',
  clinic: 'Consultório/Clínica',
  building: 'Prédio/Edifício Inteiro',
  hotel: 'Hotel/Motel/Pousada',
};

const StaticMapController = () => {
  const { exactPropertyCoordinates } = useSelector(
    (state) => state.addPropertyFormValues
  );
  const map = useMap();
  useEffect(() => {
    if (!exactPropertyCoordinates) return;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        map.removeLayer(layer);
      }
    });
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    if (map.tap) map.tap.disable();
    const marker = new L.Marker([
      exactPropertyCoordinates[1],
      exactPropertyCoordinates[0],
    ]).bindTooltip('Meu imóvel', {
      permanent: true,
      direction: 'top',
      offset: [-18, -20],
    });
    marker.options.draggable = false;
    marker.options.autoPan = true;
    // marker.options.title = 'Meu imóvel';
    marker.addTo(map);

    // return () => {
    //   map.removeLayer(marker);
    // };
  }, [exactPropertyCoordinates, map]);
  return null;
};

const MapController = ({
  searchAddressCoordinates,
  setValue,
  continueButtonRef,
}) => {
  const map = useMap();

  const dispatch = useDispatch();
  map.on('click', (e) => {
    const { lat, lng } = e.latlng;
    console.log(lat, lng);
    // If there is already a marker, remove it
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        map.removeLayer(layer);
      }
    });
    // Add a marker to the map
    const marker = new L.Marker([lat, lng]);
    marker.options.draggable = true;
    marker.options.autoPan = true;
    marker.options.title = 'Meu imóvel';
    marker.addTo(map);
    // Fly to the marker
    // map.flyTo([lat, lng], 18);
    // Set the coordinates to the react-hook-form
    setValue('lat', lat);
    setValue('lng', lng);
    dispatch(
      addPropertyFormValuesActions.setExactPropertyCoordinates([lng, lat])
    );
    setTimeout(() => {
      continueButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  });
  useEffect(() => {
    if (!searchAddressCoordinates) return;
    if (searchAddressCoordinates.length > 2) {
      map.flyToBounds(
        [
          [searchAddressCoordinates[1], searchAddressCoordinates[0]],
          [searchAddressCoordinates[3], searchAddressCoordinates[2]],
        ],
        { padding: [50, 50], maxZoom: 18 }
      );
    } else {
      map.flyTo(searchAddressCoordinates, 12);
    }
  }, [searchAddressCoordinates, map]);
  return null;
};

const FirstStepForm = ({
  register,
  setValue,
  getValues,
  watch,
  handleCepChange,
  handleMapSearch,
  handleNext,
  isSearchAddressMapDialogOpen,
  setIsSearchAddressMapDialogOpen,
}) => {
  const { fullAddress, searchAddressCoordinates, exactPropertyCoordinates } =
    useSelector((state) => state.addPropertyFormValues);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const searchMapRef = useRef();
  const searchCepRef = useRef();
  const continueButtonRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchAddressMapDialog = () => {
    setIsSearchAddressMapDialogOpen(false);
  };

  return (
    <div className='flex flex-col gap-6'>
      <Dialog
        open={isSearchAddressMapDialogOpen}
        onClose={handleSearchAddressMapDialog}
        // maxWidth='xl'
        fullWidth
      >
        {/* <DialogTitle>Localização do imóvel</DialogTitle> */}
        {/* <DialogContent> */}
        {/* <DialogContentText>
                Clique no mapa para adicionar um marcador na localização exata
                do imóvel
              </DialogContentText> */}
        <MapContainer
          center={[-23.5505, -46.6333]}
          zoom={12}
          // scrollWheelZoom={false}
          // key={'small'}
          // zoomControl={false}
          style={{ width: '100%', height: '100vh' }}
        >
          <MapController
            searchAddressCoordinates={searchAddressCoordinates}
            setValue={setValue}
            continueButtonRef={continueButtonRef}
          />
          <TileLayer
            attribution='Rentia'
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
        </MapContainer>
        {/* </DialogContent> */}
        <DialogActions sx={{ m: 'auto' }}>
          <button
            type='button'
            className='w-32 p-2 my-2 text-black text-sm font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
            onClick={handleSearchAddressMapDialog}
            disabled={exactPropertyCoordinates ? false : true}
          >
            Confirmar
          </button>
        </DialogActions>
      </Dialog>
      <div>
        <h3 className='mb-3 text-base md:text-lg font-medium text-gray-900 '>
          O que você deseja?
        </h3>
        <ul className='grid w-full gap-6 md:grid-cols-2'>
          <li>
            <input
              {...register('action')}
              type='radio'
              id='rent'
              name='rentOrSale'
              value='rent'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('action', e.target.value);
                  return old;
                });
                setValue('action', 'rent');
              }}
              defaultChecked={
                searchParams.get('action') === 'rent' ||
                !searchParams.get('action')
              }
            />
            <label
              htmlFor='rent'
              className='flex w-full p-2 text-emerald-700 bg-white border border-emerald-500 rounded-lg cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Alugar</p>
            </label>
          </li>
          <li>
            <input
              {...register('action')}
              type='radio'
              id='sale'
              name='rentOrSale'
              value='sale'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('action', e.target.value);
                  return old;
                });
                setValue('action', 'sale');
              }}
              defaultChecked={searchParams.get('action') === 'sale'}
            />
            <label
              htmlFor='sale'
              className='flex w-full p-2 text-emerald-700 bg-white border border-emerald-500 rounded-lg cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Vender</p>
            </label>
          </li>
        </ul>
      </div>
      <div>
        <h3 className='mb-3 text-base md:text-lg font-medium text-gray-900 '>
          Tipo de imóvel:
        </h3>
        <ul className='grid w-full gap-6 md:grid-cols-2'>
          <li>
            <input
              {...register('mainType')}
              type='radio'
              id='residential'
              name='residentialOrCommercial'
              value='residential'
              className='hidden peer'
              onChange={(e) => {
                console.log(e.target.value);
                setSearchParams((old) => {
                  old.set('mainType', e.target.value);
                  old.set('type', Object.keys(residentialTypes)[0]);
                  return old;
                });
                setValue('type', Object.keys(residentialTypes)[0]);
                setValue('mainType', 'residential');
              }}
              required
              defaultChecked={
                searchParams.get('mainType') === 'residential' ||
                !searchParams.get('mainType')
              }
            />
            <label
              htmlFor='residential'
              className='flex w-full p-2 text-emerald-700 bg-white border border-emerald-500 rounded-lg cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>
                Residencial
              </p>
            </label>
          </li>
          <li>
            <input
              {...register('mainType')}
              type='radio'
              id='commercial'
              name='residentialOrCommercial'
              value='commercial'
              className='hidden peer'
              onChange={(e) => {
                console.log(e.target.value);
                setSearchParams((old) => {
                  old.set('mainType', e.target.value);
                  old.set('type', Object.keys(commercialTypes)[0]);
                  return old;
                });
                setValue('type', Object.keys(commercialTypes)[0]);
                setValue('mainType', 'commercial');
              }}
              defaultChecked={searchParams.get('mainType') === 'commercial'}
            />
            <label
              htmlFor='commercial'
              className='flex w-full p-2 text-emerald-700 bg-white border border-emerald-500 rounded-lg cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>
                Comercial
              </p>
            </label>
          </li>
        </ul>
      </div>
      <div className='flex items-center gap-2'>
        <select
          {...register('type')}
          id='type'
          defaultValue={
            searchParams.get('maynType') !== null
              ? searchParams.get('mainType') === 'residential'
                ? searchParams.get('type') || Object.keys(residentialTypes)[0]
                : searchParams.get('type') || Object.keys(commercialTypes)[0]
              : Object.keys(residentialTypes)[0]
          }
          className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
          onChange={(e) => {
            setSearchParams((old) => {
              old.set('type', e.target.value);
              return old;
            });
          }}
        >
          {searchParams.get('mainType') === 'residential' ||
          !searchParams.get('mainType')
            ? Object.keys(residentialTypes).map((key) => (
                <option key={key} value={key}>
                  {residentialTypes[key]}
                </option>
              ))
            : Object.keys(commercialTypes).map((key) => (
                <option key={key} value={key}>
                  {commercialTypes[key]}
                </option>
              ))}
        </select>
      </div>
      <div>
        <h3 className='mb-3 text-base md:text-lg font-medium text-gray-900 '>
          Endereço do imóvel:
        </h3>
      </div>
      <div>
        <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
          CEP:
        </h3>
        <input
          {...register('cep')}
          type='text'
          inputMode='numeric'
          id='cep'
          placeholder='CEP'
          className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
          onChange={(e) => handleCepChange(e, searchCepRef)}
          required
        />
      </div>
      {fullAddress && (
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-8'>
            <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
              Cidade:
            </h3>
            <input
              {...register('city')}
              disabled
              value={getValues('city') || ''}
              type='text'
              inputMode='numeric'
              id='cep'
              className='w-full h-10 px-2 text-black duration-150 cursor-not-allowed bg-slate-200 rounded-lg outline-emerald-500'
            />
          </div>
          <div className='col-span-4'>
            <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
              Estado:
            </h3>
            <input
              {...register('state')}
              disabled
              value={getValues('state') || ''}
              type='text'
              inputMode='numeric'
              id='cep'
              className='w-full h-10 px-2 text-black duration-150 cursor-not-allowed bg-slate-200 rounded-lg outline-emerald-500'
            />
          </div>
        </div>
      )}
      {fullAddress && (
        <div>
          <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
            Bairro:
          </h3>
          <input
            {...register('neighborhood')}
            defaultValue={getValues('neighborhood') || ''}
            type='text'
            inputMode='numeric'
            id='cep'
            className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
            required
          />
        </div>
      )}
      {fullAddress && (
        <div>
          <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
            Endereço:
          </h3>
          <input
            {...register('address')}
            defaultValue={getValues('address') || ''}
            type='text'
            inputMode='text'
            id='address'
            placeholder='Endereço'
            className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
            required
          />
        </div>
      )}
      {fullAddress && (
        <div className='grid grid-cols-12 gap-2'>
          <div className='col-span-4'>
            <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
              Número:
            </h3>
            <input
              {...register('houseNumber')}
              type='text'
              inputMode='numeric'
              id='houseNumber'
              className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
              required
            />
          </div>
          <div className='col-span-8' ref={searchCepRef}>
            <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500 '>
              Complemento:
            </h3>
            <input
              {...register('complement')}
              type='text'
              inputMode='text'
              id='complement'
              className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
            />
          </div>
        </div>
      )}
      {fullAddress && getValues('address') && (
        <div className='flex gap-2 items-center'>
          <h3 className='text-base md:text-lg font-medium text-gray-900 '>
            Selecione o local no mapa:
          </h3>
          <InfoOutlined
            onClick={() => setOpenInfoDialog(true)}
            className='text-emerald-700 cursor-pointer'
          />
          <Dialog
            onClose={() => setOpenInfoDialog(false)}
            aria-labelledby='buscar-no-mapa'
            open={openInfoDialog}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id='buscar-no-mapa'>
              Buscar no mapa
            </DialogTitle>
            <IconButton
              aria-label='close'
              onClick={() => setOpenInfoDialog(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseOutlined />
            </IconButton>
            <DialogContent dividers>
              <div className='flex flex-col items-center justify-center rounded gap-4'>
                <p className='text-black'>
                  Após clicar em{' '}
                  <span className='text-emerald-800 font-semibold'>
                    Buscar no mapa
                  </span>
                  , o mapa será mostrado e direcionado para o endereço
                  cadastrado. Clique em qualquer ponto do mapa para adicionar um
                  marcador e registrar a localização do seu imóvel.
                </p>
                <p className='text-black'>
                  O botão{' '}
                  <span className='text-emerald-800 font-semibold'>
                    Continuar
                  </span>{' '}
                  só será ativado após a localização do imóvel ser registrada no
                  mapa.
                </p>
                <p className='text-black'>
                  <span className='text-emerald-800 font-semibold'>
                    Lembre-se:
                  </span>{' '}
                  quanto mais aproximado do local exato do imóvel, mais chances
                  de ser encontrado pelos interessados.
                </p>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
      {fullAddress && watch('address') && (
        <div className='mx-auto my-8'>
          <button
            type='button'
            onClick={() => handleMapSearch(searchMapRef)}
            className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg'
          >
            Buscar no mapa
          </button>
        </div>
      )}
      {console.log(searchAddressCoordinates)}
      {exactPropertyCoordinates && (
        <div
          className='border border-emerald-700 shadow-2xl'
          ref={searchMapRef}
        >
          <MapContainer
            center={[exactPropertyCoordinates[1], exactPropertyCoordinates[0]]}
            zoom={17}
            scrollWheelZoom={false}
            key={'small'}
            style={{ width: '100%', height: '50vh' }}
            markerZoomAnimation={true}
            dragging={false}
            zoomControl={false}
          >
            <TileLayer
              attribution='Rentia'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <StaticMapController />
          </MapContainer>
        </div>
      )}
      {fullAddress &&
        searchAddressCoordinates &&
        exactPropertyCoordinates &&
        getValues('address') && (
          <div className='mx-auto my-8' ref={continueButtonRef}>
            <button
              type='button'
              className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
              onClick={handleNext}
            >
              Continuar
            </button>
          </div>
        )}
    </div>
  );
};

export default FirstStepForm;
