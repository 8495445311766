import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import AddPropertyStepper from '../components/Layout/AddPropertyStepper';
import { useDispatch, useSelector } from 'react-redux';
import { addPropertyFormValuesActions } from '../store/addPropertyFormValues';
import { Step, StepLabel, Stepper } from '@mui/material';
import FirstStepForm from '../components/AddProperty/FirstStepForm';
import SecondStepForm from '../components/AddProperty/SecondStepForm';
import imageCompression from 'browser-image-compression';
import ThirdStepForm from '../components/AddProperty/ThirdStepForm';
import { searchAddress } from '../services/mapService';
import { ToastContainer, toast } from 'react-toastify';
import { serverUrl } from '../config/config';

const steps = ['Informações do imóvel', 'Detalhes do anúncio', 'Finalizar'];
const formHeader = {
  firstStep: {
    headline: 'Vamos adicionar seu imóvel',
    description:
      'Inicie o cadastro, é rápido. Caso não tenha todas as informações agora, você poderá editar o anúncio depois.',
  },
  secondStep: {
    headline: 'Detalhes do anúncio',
    description:
      'Complete as informações do anúncio. Você poderá editar o anúncio depois.',
  },
  thirdStep: {
    headline: 'Capriche nas fotos',
    description:
      'Adicione as fotos do seu imóvel e o nome dos cômodos. Fotos de qualidade aumentam as chances de fechar negócio em até 4x.',
  },
};

const AddPropertyPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const dispatch = useDispatch();

  const { register, handleSubmit, getValues, setValue, watch, reset } = useForm(
    {
      defaultValues: {
        action: searchParams.get('action') || 'rent',
        mainType: searchParams.get('mainType') || 'residential',
        type: searchParams.get('type') || 'apartment',
        bedrooms: searchParams.get('bedrooms') || 0,
        suites: searchParams.get('suites') || 0,
        bathrooms: searchParams.get('bathrooms') || 0,
        parkingSlots: searchParams.get('parkingSlots') || 0,
        floors: searchParams.get('floors') || 1,
        floorNumber: searchParams.get('floorNumber') || 1,
      },
    }
  );

  useEffect(() => {
    reset(); // Reset the form values when the form is unmounted
    return () => {};
  }, [reset]);

  const [activeStep, setActiveStep] = useState(0);
  const [isSearchAddressMapDialogOpen, setIsSearchAddressMapDialogOpen] =
    useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCepChange = (e, ref) => {
    let cep = e.target.value;
    const cepRegex = /^\d{5}-?\d{3}$/; // Matches 00000000 or 00000-000

    if (cepRegex.test(cep)) {
      // If the input value matches the format 00000000 or 00000-000
      cep = cep.replace('-', ''); // Convert to the format 00000000
      consultaCep(cep, ref); // Call consultaCep with the input value
    }
  };

  const consultaCep = async (cep, ref) => {
    if (cep.length !== 8) {
      return;
    }

    try {
      const response = await fetch(`${serverUrl}/cep?cep=${cep}`);
      const data = await response.json();
      if (!response.ok) {
        toast.error(data.message);
        return;
      }
      console.log(data);
      setValue('cep', data.cep);
      setValue('address', data.logradouro);
      setValue('city', data.cidade);
      setValue('neighborhood', data.bairro);
      setValue('state', data.estado);
      setSearchParams((old) => {
        old.set('address', data.cep);
        return old;
      });
      dispatch(addPropertyFormValuesActions.setFullAddress(data));
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMapSearch = async (ref) => {
    if (!getValues('address') || !getValues('city') || !getValues('state')) {
      toast.error('Preencha o endereço corretamente');
      return;
    }
    const address = getValues('address');
    const city = getValues('city');
    const state = getValues('state');
    const searchFullAddress = `${address}, ${city} - ${state}`;
    const result = await searchAddress(searchFullAddress);
    if (result.length === 0) {
      toast.error('Endereço não encontrado');
      return;
    }
    setIsSearchAddressMapDialogOpen(true);
    // console.log('result: ', result);
    if (result[0].geometry.type === ('LineString' || 'Polygon')) {
      dispatch(
        addPropertyFormValuesActions.setSearchAddressCoordinates(result[0].bbox)
      );

      // setTimeout(() => {
      //   ref.current.scrollIntoView({ behavior: 'smooth' });
      // }, 500);

      return;
    } else {
      dispatch(
        addPropertyFormValuesActions.setSearchAddressCoordinates(
          result[0].geometry.coordinates
        )
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    setSearchParams((old) => {
      for (const key in data) {
        console.log(key, data[key]);
        old.set(key, data[key]);
      }
      return old;
    });
  };

  return (
    <div>
      <section className='flex flex-col mt-12 mx-auto max-w-screen-lg'>
        <div className='hidden md:block'>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className='my-12 px-4 w-4/5 md:w-1/2 mx-auto xs:mx-0'>
          <h1 className='mb-4 text-start text-xl  font-semibold'>
            {activeStep === 0
              ? formHeader.firstStep.headline
              : activeStep === 1
              ? formHeader.secondStep.headline
              : formHeader.thirdStep.headline}
          </h1>
          <p>
            {activeStep === 0
              ? formHeader.firstStep.description
              : activeStep === 1
              ? formHeader.secondStep.description
              : formHeader.thirdStep.description}
          </p>
        </div>

        <div className='flex max-w-screen-lg justify-center xs:justify-start px-4'>
          <form
            className={`grid grid-cols-1 gap-6 w-5/6 mb-8 ${
              activeStep === 2 ? 'md:9/12' : 'md:w-1/2'
            }`}
            onSubmit={handleSubmit(onSubmit)}
          >
            {activeStep === 0 && (
              <FirstStepForm
                register={register}
                handleCepChange={handleCepChange}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                handleMapSearch={handleMapSearch}
                handleNext={handleNext}
                isSearchAddressMapDialogOpen={isSearchAddressMapDialogOpen}
                setIsSearchAddressMapDialogOpen={
                  setIsSearchAddressMapDialogOpen
                }
              />
            )}
            {activeStep === 1 && (
              <SecondStepForm
                register={register}
                handleCepChange={handleCepChange}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                handleNext={handleNext}
              />
            )}
            {activeStep === 2 && (
              <ThirdStepForm
                register={register}
                handleCepChange={handleCepChange}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                handleSubmit={handleSubmit}
              />
            )}
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddPropertyPage;
