import React from 'react';
import { useNavigate } from 'react-router-dom';

const ServicesPreviewCard = ({ src, title, description, badge, page }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/${page}`)}
      className='max-w-72 rounded lg:w-72 md:w-80 overflow-hidden shadow-xl bg-white hover:scale-105 cursor-pointer duration-200 hover:shadow-2xl'
    >
      <img className='w-full h-40' src={src} alt='Sunset in the mountains' />
      <div className='px-6 py-4'>
        <div className='font-bold text-lg mb-2'>{title}</div>
        <p className='text-gray-700 text-sm'>{description}</p>
      </div>
      <div className='px-6 pt-4 pb-2'>
        <span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2'>
          {badge}
        </span>
      </div>
    </div>
  );
};

export default ServicesPreviewCard;
