import React from 'react';
import { set, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const SearchFilters = ({ onSubmit }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const { searchMode, selectedResult } = useSelector(
    (state) => state.searchValues
  );

  const { register, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: {},
  });

  return (
    <div>
      <section className='my-4 mx-auto'>
        <div className='flex  justify-center px-4'>
          <form
            className='grid gap-6 md:gap-x-16 grid-cols-1 md:grid-cols-2'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Tipo de imóvel
              </label>
              <select
                {...register('mainType')}
                id='mainType'
                defaultValue={searchParams.get('mainType') || 'any'}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('mainType', e.target.value);
                    return old;
                  });
                }}
              >
                <option value='any'>Qualquer</option>
                <option value='residential'>Residencial</option>
                <option value='commercial'>Comercial</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Estilo
              </label>
              {console.log(watch('mainType'))}
              {searchParams.get('mainType') === 'residential' ||
              searchParams.get('mainType') === 'any' ||
              searchParams.get('mainType') === null ? (
                <select
                  {...register('type')}
                  id='type'
                  defaultValue={searchParams.get('type') || 'any'}
                  className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('type', e.target.value);
                      return old;
                    });
                  }}
                >
                  <option value='any'>Qualquer</option>
                  <option value='apartment'>Apartamento</option>
                  <option value='house'>Casa</option>
                  <option value='farm'>Sítio/Fazenda/Chácara</option>
                  <option value='terrain'>Terreno/Lote</option>
                </select>
              ) : (
                <select
                  {...register('type')}
                  id='type'
                  defaultValue={searchParams.get('type') || 'any'}
                  className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('type', e.target.value);
                      return old;
                    });
                  }}
                >
                  <option value='any'>Qualquer</option>
                  <option value='office'>Sala/Conjunto</option>
                  <option value='store'>Ponto Comercial/Loja/Box</option>
                  <option value='depot'>Depósito/Galpão/Armazém</option>
                  <option value='clinic'>Consultório/Clínica</option>
                  <option value='building'>Prédio/Edifício Inteiro</option>
                  <option value='hotel'>Hotel/Motel/Pousada</option>
                </select>
              )}
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Valor (R$)
              </label>
              <div id='price' className='flex gap-2 xs:gap-2'>
                <input
                  {...register('minPrice')}
                  type='number'
                  placeholder='Min'
                  step={100}
                  min={0}
                  defaultValue={searchParams.get('minPrice') || ''}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('minPrice', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
                />

                <input
                  {...register('maxPrice')}
                  type='number'
                  placeholder='Max'
                  step={100}
                  min={0}
                  defaultValue={searchParams.get('maxPrice') || ''}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('maxPrice', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 p-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
                />
              </div>
            </div>

            {searchMode !== 'selectedPolygon' &&
              searchMode !== 'selectedDrawnArea' &&
              searchMode !== 'drawnArea' && (
                <div className='flex flex-col items-start gap-2'>
                  <label className='w-32 text-emerald-700 font-medium'>
                    Raio de busca
                  </label>
                  <select
                    {...register('searchRadius')}
                    id='searchRadius'
                    defaultValue={searchParams.get('searchRadius') || 'any'}
                    className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                    onChange={(e) => {
                      setSearchParams((old) => {
                        old.set('searchRadius', e.target.value);
                        return old;
                      });
                    }}
                  >
                    <option value='0.1'>100m</option>
                    <option value='0.5'>500m</option>
                    <option value='1'>1km</option>
                    <option value='2'>2km</option>
                    <option value='3'>3km</option>
                  </select>
                </div>
              )}
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Anunciado em
              </label>
              <select
                {...register('dateCreate')}
                id='dateCreate'
                defaultValue={searchParams.get('dateCreate') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('dateCreate', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='1'>24 horas</option>
                <option value='3'>3 dias</option>
                <option value='7'>7 dias</option>
                <option value='14'>14 dias</option>
                <option value='30'>30 dias</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Portaria 24h
              </label>
              <select
                {...register('concierge')}
                id='concierge'
                defaultValue={searchParams.get('concierge') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('concierge', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Fiador
              </label>
              <select
                {...register('guarantor')}
                id='guarantor'
                defaultValue={searchParams.get('guarantor') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('guarantor', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Caução
              </label>
              <select
                {...register('deposit')}
                id='deposit'
                defaultValue={searchParams.get('deposit') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('deposit', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Mobiliado
              </label>
              <select
                {...register('furnished')}
                id='furnished'
                defaultValue={searchParams.get('furnished') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('furnished', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Elevador
              </label>
              <select
                {...register('elevator')}
                id='elevator'
                defaultValue={searchParams.get('elevator') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('elevator', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Aceita pet
              </label>
              <select
                {...register('petFriendly')}
                id='petFriendly'
                defaultValue={searchParams.get('pet') || 'any'}
                onChange={(e) => {
                  setSearchParams((old) => {
                    old.set('pet', e.target.value);
                    return old;
                  });
                }}
                className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
              >
                <option value='any'>Qualquer</option>
                <option value='true'>Sim</option>
                <option value='false'>Não</option>
              </select>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Área (m²)
              </label>
              <div id='area' className='flex gap-2 xs:gap-2'>
                <input
                  {...register('minArea')}
                  type='number'
                  placeholder='Min'
                  step={10}
                  min={0}
                  defaultValue={searchParams.get('minArea') || ''}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('minArea', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
                />

                <input
                  {...register('maxArea')}
                  type='number'
                  placeholder='Max'
                  step={10}
                  min={0}
                  defaultValue={searchParams.get('maxArea') || ''}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('maxArea', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 p-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
                />
              </div>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Quartos
              </label>
              <div id='bedrooms' className='flex gap-2 xs:gap-2'>
                <select
                  {...register('minBedrooms')}
                  id='minBedrooms'
                  defaultValue={searchParams.get('minBedrooms') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('minBedrooms', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Min</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxBedrooms')}
                  id='maxBedrooms'
                  defaultValue={searchParams.get('maxBedrooms') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('maxBedrooms', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Max</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Garagem
              </label>
              <div className='flex gap-2 xs:gap-2'>
                <select
                  {...register('minParkingSlots')}
                  id='minParkingSlots'
                  defaultValue={searchParams.get('minParkingSlots') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('minParkingSlots', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Min</option>
                  <option value='1'>0</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxParkingSlots')}
                  id='maxParkingSlots'
                  defaultValue={searchParams.get('maxParkingSlots') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('maxParkingSlots', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Max</option>
                  <option value='1'>0</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Banheiros
              </label>
              <div className='flex gap-2 xs:gap-2'>
                <select
                  {...register('minBathrooms')}
                  id='minBathrooms'
                  defaultValue={searchParams.get('minBathrooms') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('minBathrooms', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Min</option>
                  <option value='1'>1 </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxBathrooms')}
                  id='maxBathrooms'
                  defaultValue={searchParams.get('maxBathrooms') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('maxBathrooms', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Max</option>
                  <option value='1'>1 </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='flex flex-col items-start gap-2'>
              <label className='w-32 text-emerald-700 font-medium'>
                Suites
              </label>
              <div className='flex gap-2 xs:gap-2'>
                <select
                  {...register('minSuites')}
                  id='minSuites'
                  defaultValue={searchParams.get('minSuites') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('minSuites', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Min</option>
                  <option value='1'>1 </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
                <select
                  {...register('maxSuites')}
                  id='maxSuites'
                  defaultValue={searchParams.get('maxSuites') || 'any'}
                  onChange={(e) => {
                    setSearchParams((old) => {
                      old.set('maxSuites', e.target.value);
                      return old;
                    });
                  }}
                  className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
                >
                  <option value='any'>Max</option>
                  <option value='1'>1 </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                </select>
              </div>
            </div>
            <div className='md:col-span-2 mx-auto my-2'>
              <button
                type='submit'
                className='w-48 h-10 md:h-12 px-2 text-sm text-black font-semibold duration-150 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg'
              >
                Buscar imóveis
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SearchFilters;
