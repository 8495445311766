import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';

const SelectedDrawnArea = ({ map, polygonHandler }) => {
  const { selectedDrawnArea, drawingControl } = useSelector(
    (state) => state.drawingAreasValues
  );

  useEffect(() => {
    if (!selectedDrawnArea) return;

    // Remove all polygons
    map.eachLayer((layer) => {
      // console.log('Layer:', layer);
      if (layer instanceof L.Polygon) {
        console.log('Removing layer:', layer);
        map.removeLayer(layer);
      }
    });

    console.log('Polygon handler:', polygonHandler);

    const drawButton = document.getElementById('drawButton');
    const removeButton = document.getElementById('removeButton');
    const searchButton = document.getElementById('searchButton');

    if (drawButton) {
      drawButton.disabled = true;
    }

    if (removeButton) {
      removeButton.disabled = false;
    }

    if (searchButton) {
      searchButton.disabled = false;
    }

    const polygon = new L.Polygon(selectedDrawnArea);
    polygon.setStyle({
      stroke: true,
      color: '#10B981',
      weight: 3,
      opacity: 1,
      fill: true,
      fillColor: '#10B981',
      fillOpacity: 0.5,
      clickable: true,
    });

    polygon.editing.enable();
    console.log('Polygon:', polygon);
    map.addLayer(polygon);
    map.flyToBounds(polygon.getBounds(), { padding: [50, 50], animate: true });
  }, [selectedDrawnArea, map]);

  return null;
};

export default SelectedDrawnArea;
