import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import L from 'leaflet';
import WrapperSearchResultsComponent from '../components/SearchResults/WrapperSearchResultsComponent';
import { mapConfig } from '../config/config';

const SearchResultsPage = () => {
  return (
    <div>
      <MapContainer
        center={mapConfig.map_default_center}
        zoom={mapConfig.map_default_zoom}
        // scrollWheelZoom={false}
        // key={isLargeDevice ? 'large' : 'small'}
        zoomControl={mapConfig.zoomControl}
        maxZoom={mapConfig.map_max_zoom}
        minZoom={mapConfig.map_min_zoom}
        style={{ width: '100dvw', height: '93.5vh' }}
        closePopupOnClick={false}
      >
        <WrapperSearchResultsComponent />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={mapConfig.map_tile_layer}
        />
      </MapContainer>
    </div>
  );
};

export default SearchResultsPage;
