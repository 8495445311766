import {
  AddAPhoto,
  CloseOutlined,
  HouseOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { addPropertyFormValuesActions } from '../../store/addPropertyFormValues';
import { addProperty } from '../../services/propertyService';
import { set } from 'react-hook-form';
import { Cross1Icon, CrossCircledIcon } from '@radix-ui/react-icons';
import { toast } from 'react-toastify';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

const ThirdStepForm = ({
  register,
  setValue,
  getValues,
  handleCepChange,
  watch,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [coverImage, setCoverImage] = useState('');
  const [openInfoPhotoDialog, setOpenInfoPhotoDialog] = useState(false);
  const dispatch = useDispatch();

  const convertToWebP = async (imageArray) => {
    setIsUploading(true);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1080,
      useWebWorker: true,
      fileType: 'image/webp',
    };

    try {
      const convertedImages = await Promise.all(
        imageArray.map(async (image) => {
          const compressedFile = await imageCompression(image, options);
          return compressedFile;
        })
      );

      setValue('images', convertedImages);
      console.log('convertedImages: ', getValues('images'));

      const data = getValues();

      // create the form data to be sent to the backend
      const formData = new FormData();
      for (const key in data) {
        if (key === 'images') {
          data[key].forEach((value, index) => {
            console.log('value: ', value);
            formData.append(
              `${key}`,
              value,
              `${value.name.split('.')[0]}.webp`
            );
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      // show the form data entries in the console
      const entries = formData.entries();
      for (const pair of entries) {
        console.log(pair);
      }

      // call the addProperty function from the propertyService
      const response = await addProperty(formData);
      console.log('response', response);
      if (response) {
        toast.success(response.message);
      }
      setIsUploading(false);
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
      },
      multiple: true,
      maxFiles: 40,
      maxSize: 4 * 1024 * 1024,

      onDrop: (acceptedFiles, fileRejections) => {
        console.log(acceptedFiles);
        const filesArray = [...files];
        // check if the file is already in the list of files

        acceptedFiles.map((file) => {
          if (filesArray.some((f) => f.name === file.name)) {
            toast.error(`${file.name} - Arquivo já adicionado`);
            return;
          }
          filesArray.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
        });
        setFiles(filesArray);
        if (acceptedFiles.length > 1) {
          toast.success(
            `${acceptedFiles.length} fotos adicionadas com sucesso`
          );
          return;
        }
        if (acceptedFiles.length === 1) {
          toast.success(`Foto adicionada com sucesso`);
        }
      },
      noDragEventsBubbling: true,
      onDropRejected: (fileRejections) => {
        // console.log(fileRejections);
        fileRejections.map((file) => {
          if (file.errors[0].code === 'file-invalid-type') {
            toast.error(`${file.file.name} - Formato de arquivo inválido`);
            return `${file.file.name} - Formato de arquivo inválido`;
          }
          if (file.errors[0].code === 'file-too-large') {
            toast.error(`${file.file.name} - Arquivo muito grande`);
            return `${file.file.name} - Arquivo muito grande`;
          }
          toast.error(`${file.file.name} - Erro desconhecido`, {
            autoClose: 4000,
          });
          return `${file.file.name} - Erro desconhecido`;
        });
      },
      onDropAccepted: (acceptedFiles) => {
        handleCoverImage(acceptedFiles[0]);
      },
    });

  // Create a function to remove a file from the list of files
  const removeFile = (file) => {
    const newFiles = files.filter((f) => f !== file);
    if (coverImage === file.path.split('.')[0]) {
      handleCoverImage(newFiles[0]);
      setFiles(newFiles);
      return;
    }
    setFiles(newFiles);
  };

  // Create a function to set the cover image
  const handleCoverImage = (file) => {
    setValue('coverImage', file.path.split('.')[0]);
    setCoverImage(file.path.split('.')[0]);
  };

  const thumbs = files.map((file) => (
    <div
      className='flex relative border-r-2 shadow-lg border gap-8 box-border hover:scale-102 duration-300'
      key={file.name}
    >
      <div className=' min-w-0 overflow-hidden '>
        <img
          src={file.preview}
          className='w-auto h-full'
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div>
          <CrossCircledIcon
            className='w-6 h-6 absolute top-2 right-2 bg-black  text-white rounded-full cursor-pointer hover:text-emerald-700'
            onClick={() => removeFile(file)}
          />
        </div>
        <div>
          <HouseOutlined
            className={`w-6 h-6 absolute bottom-2 right-2 bg-black text-white rounded-full cursor-pointer hover:text-emerald-700 ${
              coverImage === file.path.split('.')[0]
                ? 'text-emerald-500'
                : 'text-gray-500'
            }`}
            onClick={() => handleCoverImage(file)}
          />
        </div>
      </div>
    </div>
  ));

  const handleSubmit = async () => {
    await convertToWebP(files);
    console.log('submitting form', getValues());
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className='flex flex-col gap-6'>
      <div>
        <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
          Adicionar fotos:
        </h3>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className='flex items-center justify-center w-full'
        >
          <label
            htmlFor='dropzone-file'
            className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100'
          >
            <div className='flex flex-col items-center justify-center p-8 gap-3'>
              <AddAPhoto fontSize='large' />
              <p className='mb-2 text-sm text-gray-500'>
                <span className='font-semibold'>Clique para adicionar</span> ou
                arraste e solte as imagens aqui
              </p>
              <p className='text-xs text-gray-500'>
                Apenas nos formatos PNG, JPG (Maximo de 4Mb por foto)
              </p>
            </div>
            <input {...getInputProps()} />
          </label>
        </div>
        {files.length > 0 && (
          <div>
            <div className='flex gap-2 items-center'>
              <h3 className='mt-3 mb-3 text-sm md:text-base font-medium text-gray-500'>
                Fotos adicionadas:
              </h3>
              <InfoOutlined
                onClick={() => setOpenInfoPhotoDialog(true)}
                className='text-emerald-700 cursor-pointer'
              />
            </div>
            <Dialog
              onClose={() => setOpenInfoPhotoDialog(false)}
              aria-labelledby='buscar-no-mapa'
              open={openInfoPhotoDialog}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id='buscar-no-mapa'>
                Foto de capa
              </DialogTitle>
              <IconButton
                aria-label='close'
                onClick={() => setOpenInfoPhotoDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseOutlined />
              </IconButton>
              <DialogContent dividers>
                <div className='flex flex-col items-center justify-center rounded gap-4'>
                  <p className='text-black'>
                    Após adicionar as fotos do imóvel, a primeira foto será
                    automaticamente escolhida como foto de capa.
                  </p>
                  <p className='text-black'>
                    Para alterar a foto de capa, clique no ícone
                    <span className='mx-1'>
                      <HouseOutlined
                        className={`w-6 h-6 bg-black text-white rounded-full `}
                      />
                    </span>
                    no canto inferior direito da foto desejada. Ao selecionar
                    uma foto de capa, o ícone irá mudar para
                    <span className='ml-1'>
                      <HouseOutlined
                        className={`w-6 h-6 bg-black text-emerald-700 rounded-full `}
                      />
                    </span>
                    .
                  </p>
                  <p className='text-black'>
                    <span className='text-emerald-800 font-semibold'>
                      Lembre-se:
                    </span>{' '}
                    a foto de capa será a primeira foto que os usuários verão ao
                    acessar o anúncio.
                  </p>
                </div>
              </DialogContent>
            </Dialog>
            <div className='w-full max-h-96 overflow-y-auto border rounded bg-slate-100 p-2 shadow-lg'>
              <aside className='flex-wrap gap-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {thumbs}
              </aside>
            </div>
          </div>
        )}
      </div>
      <div className='mx-auto my-8'>
        <button
          type='button'
          disabled={files.length === 0}
          className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
          onClick={handleSubmit}
        >
          {isUploading && (
            <svg
              aria-hidden='true'
              role='status'
              className='inline w-4 h-4 me-3 text-white animate-spin'
              viewBox='0 0 100 101'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                fill='#10B981'
              />
              <path
                d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                fill='#9fe0bf'
              />
            </svg>
          )}
          {isUploading ? 'Enviando...' : 'Salvar Imóvel'}
        </button>
      </div>
    </div>
  );
};

export default ThirdStepForm;
