export const drawingButtonsClassName =
  'flex bg-none lg:bg-slate-50 p-4 mx-auto lg:mr-8 border-gray-400 rounded shadow gap-4';

// Mix the disabled and enabled classes to create the drawButtonClassName using disable: prefix from tailwindcss
export const drawButtonClassName =
  'leaflet-draw-toolbar-button-enabled bg-white hover:bg-emerald-500 active:bg-gray-200 focus:border-green-600 text-gray-800 font-semibold text-xs h-8 w-20 border border-gray-400 rounded shadow disabled:bg-gray-200 disabled:cursor-not-allowed duration-500';
export const removeButtonClassName =
  'bg-white hover:bg-red-300 active:bg-gray-200 focus:border-green-600 text-gray-800 font-semibold text-xs h-8 w-20 border border-gray-400 rounded shadow disabled:bg-gray-200 disabled:cursor-not-allowed duration-500';
export const searchButtonClassName =
  'bg-emerald-500 hover:bg-emerald-500 active:bg-green-200 focus:border-green-600 text-gray-800 font-semibold text-xs h-8 w-20 border border-gray-400 rounded shadow disabled:bg-gray-200 disabled:cursor-not-allowed duration-500';
