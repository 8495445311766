import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@uidotdev/usehooks';
import {
  MapContainer,
  FeatureGroup,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMap,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L, { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import NavBar from '../components/Layout/NavBar';
import DrawnAreasBar from '../components/Layout/DrawnAreasBar';
import { useDispatch, useSelector } from 'react-redux';

import DrawnAreasPainel from '../components/Layout/DrawnAreasPainel';

import WrapperDrawingComponent from '../components/Drawing-mode/WrapperDrawingComponent';
import {
  drawButtonClassName,
  drawingButtonsClassName,
  removeButtonClassName,
  searchButtonClassName,
} from '../utils/buttonsClasses';
import { drawingMapConfig } from '../config/config';
const polyUtil = require('polyline-encoded');

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const DrawingModePage = () => {
  const divRef = useRef(null);

  const { selectedDrawnArea } = useSelector(
    (state) => state.drawingAreasValues
  );

  const isLargeDevice = useMediaQuery('only screen and (min-width : 1023px)');

  //uncomment next line to change the default tooltip text
  L.drawLocal.draw.handlers.polygon.tooltip.start =
    'Desenhe a área desejada no mapa';
  L.drawLocal.draw.handlers.polygon.tooltip.cont =
    'Continue desenhando até completar a área';
  L.drawLocal.draw.handlers.polygon.tooltip.end =
    'Clique no primeiro ponto para finalizar a área';

  L.drawLocal.edit.handlers.edit.tooltip.text = 'Arraste os pontos para editar';
  L.drawLocal.edit.handlers.edit.tooltip.subtext = '';
  // console.log('Draw:', L.drawLocal);

  return (
    <div>
      <div className='lg:hidden block'>
        <DrawnAreasBar />
      </div>
      <div className='flex'>
        <DrawnAreasPainel />
        <MapContainer
          center={drawingMapConfig.map_default_center}
          zoom={drawingMapConfig.map_default_zoom}
          minZoom={drawingMapConfig.map_min_zoom}
          maxZoom={drawingMapConfig.map_max_zoom}
          key={isLargeDevice ? 'large' : 'small'}
          zoomControl={false}
          style={{ width: '100vw', height: isLargeDevice ? '100vh' : '94vh' }}
        >
          {/* <FeatureGroup> */}
          <div ref={divRef}>
            <WrapperDrawingComponent />
            {/* <EditControl
                id='drawControl'
                position={isLargeDevice ? 'topright' : 'bottomright'}
                onMounted={onMounted}
                //   onEdited={onEdited}
                //   onEditStop={onEditStop}
                //   onEditMove={onEditStop}
                //   onEditResize={onEditStop}
                onEditVertex={onEditVertex}
                onCreated={onCreated}
                onDeleted={onDeleted}
                //   onDrawVertex={onDrawVertex}
                draw={{
                  polygon: {
                    allowIntersection: false,
                    showArea: true,
                    drawError: {
                      color: '#FF0000',
                      message:
                        '<strong>As linhas não podem se cruzar!</strong>',
                    },
                    shapeOptions: {
                      stroke: true,
                      color: '#10B981',
                      weight: 3,
                      opacity: 1,
                      fill: true,
                      fillColor: '#10B981',
                      fillOpacity: 0.5,
                      clickable: true,
                    },
                    metric: ['km', 'm'],
                    precision: 2,
                    guidelineDistance: 20,
                  },
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polyline: false,
                }}
                edit={{
                  edit: true,
                  remove: true,
                  poly: {
                    showArea: true,
                    drawError: {
                      color: '#10B981',
                    },
                  },
                }}
              /> */}
          </div>
          <TileLayer
            attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
            url={drawingMapConfig.map_tile_layer}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default DrawingModePage;
