import useSWR from 'swr';
import { serverUrl } from '../config/config';

// Create a get method to fetch the alerts
export const getAlerts = async () => {
  try {
    const response = await fetch(`${serverUrl}/alerts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a post method to create an alert
export const createAlert = async (alert) => {
  try {
    const response = await fetch(`${serverUrl}/alerts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(alert),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a delete method to delete an alert
export const deleteAlert = async (id) => {
  try {
    const response = await fetch(`${serverUrl}/alerts/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a put method to update an alert
export const updateAlert = async (alert) => {
  try {
    const response = await fetch(`${serverUrl}/alerts/${alert.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(alert),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useGetAlerts = () => {
  const { data, error, isLoading } = useSWR(`alerts`, getAlerts);

  return {
    alertsList: data,
    isLoading,
    isError: error,
  };
};
