import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  menuDrawerOpen: false,
  loginDrawerOpen: false,
  drawnAreasOpen: false,
};

const drawerValuesSlice = createSlice({
  name: 'drawerValues',
  initialState: initialFormState,
  reducers: {
    setMenuDrawer(state, action) {
      state.menuDrawerOpen = action.payload;
    },
    setLoginDrawer(state, action) {
      state.loginDrawerOpen = action.payload;
    },
    setDrawnAreasDrawer(state, action) {
      state.drawnAreasOpen = action.payload;
    },
  },
});

export const drawerValuesActions = drawerValuesSlice.actions;

export default drawerValuesSlice.reducer;
