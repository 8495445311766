import { ControlPosition, MapControl } from '@vis.gl/react-google-maps';
import React from 'react';

const DrawingButtons = ({ polygon, setPolygon }) => {
  const clearPolygon = () => {
    polygon.setMap(null);
    setPolygon(null);
  };
  return (
    <MapControl position={ControlPosition.TOP_RIGHT}>
      <div className='bg-white p-4 m-2 border-gray-400 rounded shadow opacity-90'>
        <button
          className={`bg-white disabled:bg-gray-200 hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow ${
            !polygon ? 'cursor-not-allowed' : ''
          }`}
          onClick={clearPolygon}
          disabled={!polygon}
        >
          Limpar Área
        </button>
        <button
          className='bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow'
          // onClick={() => savePolygon(polygon)}
        >
          Desfazer
        </button>
        <button
          className='bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow'
          // onClick={() => savePolygon(polygon)}
        >
          Refazer
        </button>
        {polygon && (
          <button
            className='bg-green-200 hover:bg-green-300 mx-2 active:bg-green-400 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow'
            // onClick={() => savePolygon(polygon)}
          >
            Ver Propriedades
          </button>
        )}
      </div>
    </MapControl>
  );
};

export default DrawingButtons;
