import useSWR from 'swr';
import { serverUrl } from '../config/config';

// Create a get method to fetch the address
export const searchAddress = async (address) => {
  try {
    const response = await fetch(
      `${serverUrl}/map?address=${address}`,
      // fetch from the server and add the address to the query
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    const data = await response.json();
    return data.features;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a get method to fetch the drawn areas
export const getDrawnAreas = async () => {
  try {
    const response = await fetch(`${serverUrl}/polygons`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useDrawnAreas = (id) => {
  const { data, error, isLoading } = useSWR(`drawnAreas`, getDrawnAreas);

  return {
    drawnAreasList: data,
    isLoading,
    isError: error,
  };
};
