import React, { useEffect, useRef, useState } from 'react';
import NavBar from '../components/Layout/NavBar';
import {
  MapContainer,
  FeatureGroup,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMap,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L, { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MovingServicePage = () => {
  const editRef = useRef(null);
  const divRef = useRef(null);
  const polygonRef = useRef(null);

  const onMounted = (e) => {
    console.log('Mounted', e);
    divRef.current = e;
    console.log('divRef', divRef.current);
  };
  useEffect(() => {
    // setTimeout(() => {
    //   const div = document.querySelector('.leaflet-draw-toolbar-top');
    //   const buttons = document.querySelector('.leaflet-draw-draw-polygon');
    //   console.log('buttons', buttons);
    //   console.log('div', div);
    //   // Create the custom div
    //   const customDiv = document.createElement('div');
    //   customDiv.className =
    //     'bg-white p-4 m-2 mr-10 border-gray-400 rounded shadow';
    //   // Replace the toolbar div with the custom div
    //   div.parentNode.replaceChild(customDiv, div);
    //   const customElement = document.createElement('button');
    //   customElement.innerHTML = 'Custom Button';
    //   customElement.className =
    //     'bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow';
    //   customElement.addEventListener('click', () => {
    //     // Simulate a click event on the original button
    //     buttons.click();
    //   });
    //   // Replace the existing button with the custom HTML element
    //   customDiv.appendChild(customElement);
    //   // Replace the toolbar buttons with custom HTML elements
    // }, 0);
  }, []);

  const onCreated = (e) => {
    const { layerType, layer } = e;
    if (layerType === 'polygon') {
      console.log('layer', layer);
      console.log(
        'New Polyon Array:',
        layer.toGeoJSON().geometry.coordinates[0]
      );
      editRef.current.click();
    }
  };

  const onEdited = (e) => {
    const { layers } = e;
    layers.eachLayer((layer) => {
      console.log(layer.toGeoJSON().geometry.coordinates[0]);
    });
  };

  const onDeleted = (e) => {
    const { layers } = e;
    layers.eachLayer((layer) => {
      console.log(layer.toGeoJSON());
    });
  };

  const onDrawVertex = (e) => {
    console.log(e);
  };

  const polygon = true;

  //use states to enable the button clicks at special times, also you can use states
  //to perform multiple actions with one button

  const [drawing, setDrawing] = useState(false);

  const handleClick = () => {
    // Get the toolbar divs

    const parentDiv = document.querySelector('.leaflet-draw.leaflet-control');
    const editControl = document.querySelectorAll('.leaflet-draw-section');
    const polygonControlDiv = editControl[0];
    const editControlDiv = editControl[1];

    polygonControlDiv.style.display = 'none';
    editControlDiv.style.display = 'none';

    // Get the buttons
    const polygonButton = document.querySelector('.leaflet-draw-draw-polygon');
    const editButton = document.querySelector('.leaflet-draw-edit-edit');
    editRef.current = document.querySelector('.leaflet-draw-edit-edit');
    console.log('Edit:', editRef.current);
    const removeButton = document.querySelector('.leaflet-draw-edit-remove');

    // Get the actions

    let polygonFinishAction;
    let polygonUndoAction;
    let polygonCancelAction;
    let editSaveAction;
    let editCancelAction;
    let removeSaveAction;
    let removeCancelAction;
    let removeClearAllAction;

    // Create the new buttons
    const newEditButton = document.createElement('button');
    newEditButton.innerHTML = 'Desfazer';
    newEditButton.className =
      'bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow';

    const newRemoveButton = document.createElement('button');
    newRemoveButton.innerHTML = 'Buscar imóveis';
    newRemoveButton.className =
      'bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow';

    // Create the custom div
    const drawingButtonsDiv = document.createElement('div');
    drawingButtonsDiv.className =
      'bg-white p-4 m-2 mr-10 border-gray-400 rounded shadow';
    // Replace the toolbar div with the custom div
    // editControlDiv.parentNode.replaceChild(drawingButtonsDiv, editControlDiv);
    const newPolygonButton = document.createElement('button');
    newPolygonButton.innerHTML = 'Remover área';
    newPolygonButton.className =
      'leaflet-draw-toolbar-button-enabled bg-white hover:bg-gray-100 mx-2 active:bg-gray-200 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow';

    drawingButtonsDiv.appendChild(newPolygonButton);
    drawingButtonsDiv.appendChild(newEditButton);
    drawingButtonsDiv.appendChild(newRemoveButton);

    parentDiv.appendChild(drawingButtonsDiv);

    newPolygonButton.addEventListener('click', () => {
      // Simulate a click event on the original button

      polygonButton.click();

      polygonFinishAction = document.getElementsByTagName('li')[0].children[0];
      polygonUndoAction = document.getElementsByTagName('li')[1].children[0];
      polygonCancelAction = document.getElementsByTagName('li')[2].children[0];
      console.log(
        'Actions:',
        polygonFinishAction,
        polygonUndoAction,
        polygonCancelAction
      );
    });
    newEditButton.addEventListener('click', () => {
      // Simulate a click event on the original button
      editButton.click();

      editSaveAction = document.getElementsByTagName('li')[0].children[0];
      editCancelAction = document.getElementsByTagName('li')[1].children[0];
      console.log('Actions:', editSaveAction, editCancelAction);
    });
    newRemoveButton.addEventListener('click', () => {
      // Simulate a click event on the original button
      removeButton.click();

      removeSaveAction = document.getElementsByTagName('li')[0].children[0];
      removeCancelAction = document.getElementsByTagName('li')[1].children[0];
      removeClearAllAction = document.getElementsByTagName('li')[2].children[0];
      console.log(
        'Actions:',
        removeSaveAction,
        removeCancelAction,
        removeClearAllAction
      );
    });
  };

  //Overview of methods you can use to access the toolbars handler for polygon drawing

  // startDraw
  //editRef.current.leafletElement._toolbars.draw._modes.polygon.handler.enable()

  // cancelDraw
  //editRef.current.leafletElement._toolbars.draw._modes.polygon.handler.disable()

  // vertexBack
  //editRef.current.leafletElement._toolbars.draw._modes.polygon.handler.deleteLastVertex()

  // stopDraw
  //editRef.current.leafletElement._toolbars.draw._modes.polygon.handler.completeShape()
  //editRef.current.leafletElement._toolbars.draw._modes.polygon.handler.disable()

  // startEdit
  //editRef.current.leafletElement._toolbars.edit._modes.edit.handler.enable()

  // cancelEdit
  //editRef.current.leafletElement._toolbars.edit._modes.edit.handler.disable()

  // saveEdit
  //editRef.current.leafletElement._toolbars.edit._modes.edit.handler.save()
  //editRef.current.leafletElement._toolbars.edit._modes.edit.handler.disable()

  // startDelete
  //editRef.current.leafletElement._toolbars.edit._modes.remove.handler.enable()

  // saveDelete
  //editRef.current.leafletElement._toolbars.edit._modes.remove.handler.save()
  //editRef.current.leafletElement._toolbars.edit._modes.remove.handler.disable()

  //example methods from section 4

  //uncomment next line to change the default tooltip text
  L.drawLocal.draw.handlers.polygon.tooltip.start =
    'Desenhe a área desejada no mapa';
  L.drawLocal.draw.handlers.polygon.tooltip.cont =
    'Continue desenhando até completar a área';
  L.drawLocal.draw.handlers.polygon.tooltip.end =
    'Clique no primeiro ponto para finalizar a área';

  L.drawLocal.edit.handlers.edit.tooltip.text = 'Arraste os pontos para editar';
  L.drawLocal.edit.handlers.edit.tooltip.subtext = '';

  //to delete handlers to avoid draw on right clicks implement onHandleMapReady function
  //there you call layer.off('**name of the handler**')

  const onShapeDrawn = (e) => {
    setDrawing(false);

    console.log('Shape Drawn:', e.layer);

    e.layer.on('click', () => {
      // editRef.current._toolbars.edit._modes.edit.handler.enable();
    });
    e.layer.on('contextmenu', () => {
      //do some contextmenu action here
      console.log('Right Clicked');
    });
    e.layer.on('add', () => {
      //do some contextmenu action here
      console.log('Polygon Added');
    });
    e.layer.bindTooltip('Text', {
      className:
        'leaflet-draw-tooltip:before leaflet-draw-tooltip leaflet-draw-tooltip-visible',
      sticky: true,
      direction: 'right',
    });
  };

  return (
    <div className=''>
      <div className='bg-white p-4 m-2 border-gray-400 rounded shadow opacity-90 z-50'>
        {polygon && (
          <button
            className='bg-green-200 hover:bg-green-300 mx-2 active:bg-green-400 focus:border-blue-600 text-gray-800 font-semibold py-4 px-6 border border-gray-400 rounded shadow'
            onClick={handleClick}
          >
            Ver Propriedades
          </button>
        )}
      </div>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        // scrollWheelZoom={false}
        style={{ width: '100vw', height: '90vh' }}
      >
        <FeatureGroup>
          <div ref={divRef}>
            <EditControl
              id='drawControl'
              position='topright'
              onMounted={onMounted}
              onEdited={onEdited}
              onCreated={onCreated}
              onDeleted={onDeleted}
              onDrawVertex={onDrawVertex}
              edit={{
                edit: true,
                remove: true,
              }}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
              }}
              layerOptions={{
                polygon: {
                  shapeOptions: {
                    color: '#6C6C6C',
                  },
                },
              }}
            />
          </div>
        </FeatureGroup>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={[51.505, -0.09]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MovingServicePage;
