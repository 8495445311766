import React, { useState } from 'react';
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Cross1Icon } from '@radix-ui/react-icons';
import { IconButton, Separator } from '@radix-ui/themes';

import logo from '../../media/real-estate.png';
import aluguel from '../../media/aluguel.png';
import compra from '../../media/compra.png';
import avaliar from '../../media/avaliar-imovel.png';
import { useNavigate } from 'react-router-dom';
import {
  ExpandLess,
  ExpandMore,
  HomeRepairServiceOutlined,
  LocalShippingOutlined,
  PhotoCameraOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';

const MenuDrawer = () => {
  const [open, setOpen] = useState(false);
  const { menuDrawerOpen } = useSelector((state) => state.drawerValues);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chooseMenu = (menu) => {
    switch (menu) {
      case 'alugar':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        navigate('/alugar');
        break;
      case 'comprar':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        navigate('/comprar');
        break;
      case 'mudar':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/mudar');
        break;
      case 'fotografar':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/fotografar');
        break;
      case 'avaliar':
        dispatch(drawerValuesActions.setMenuDrawer(false));
        setOpen(false);
        navigate('/avaliar');
        break;
    }
  };
  return (
    <Drawer
      open={menuDrawerOpen}
      onClose={() => dispatch(drawerValuesActions.setMenuDrawer(false))}
      anchor='left'
    >
      <div className='w-80'>
        <div className='flex justify-between p-4'>
          <img src={logo} alt='logo' className='w-8 h-8' />
          <h1 className='font-semibold text-xl '>Rentia</h1>

          <IconButton
            className='hover:cursor-pointer'
            onClick={() => dispatch(drawerValuesActions.setMenuDrawer(false))}
          >
            <Cross1Icon />
          </IconButton>
        </div>
        <Divider my='3' size='4' />
        <List>
          <ListItem key='Alugar' onClick={() => chooseMenu('alugar')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={aluguel} />
              </ListItemIcon>
              <ListItemText primary='Alugar' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Comprar' onClick={() => chooseMenu('comprar')}>
            <ListItemButton>
              <ListItemIcon>
                <img width={25} src={compra} />
              </ListItemIcon>
              <ListItemText primary='Comprar' />
            </ListItemButton>
          </ListItem>

          <ListItem
            key='Serviços'
            onClick={() => {
              setOpen((prev) => !prev);
              //   chooseMenu('comercial');
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <HomeRepairServiceOutlined sx={{ fontSize: '30px' }} />
              </ListItemIcon>
              <ListItemText primary='Serviços' />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open} timeout='auto'>
            <List component='div' disablePadding>
              <ListItem
                sx={{ pl: 4 }}
                onClick={() => {
                  chooseMenu('mudar');
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalShippingOutlined />
                  </ListItemIcon>
                  <ListItemText primary='Mudança' />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                onClick={() => {
                  chooseMenu('fotografar');
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PhotoCameraOutlined />
                  </ListItemIcon>
                  <ListItemText primary='Fotografar Imóvel' />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={{ pl: 4 }}
                onClick={() => {
                  chooseMenu('avaliar');
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <img width={25} src={avaliar} />
                  </ListItemIcon>
                  <ListItemText primary='Avaliar Imóvel' />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
