import { configureStore } from '@reduxjs/toolkit';

import loginValuesReducer from './loginValues';
import drawerValuesReducer from './drawerValues';
import drawingAreasValuesReducer from './drawingAreasValues';
import addPropertyFormValuesReducer from './addPropertyFormValues';
import searchValuesReducer from './searchValues';

const store = configureStore({
  reducer: {
    loginValues: loginValuesReducer,
    drawerValues: drawerValuesReducer,
    drawingAreasValues: drawingAreasValuesReducer,
    addPropertyFormValues: addPropertyFormValuesReducer,
    searchValues: searchValuesReducer,
  },
});

export default store;
