import React, { useEffect, useRef, useState } from 'react';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import Carousel from 'react-multi-carousel';
import housePlaceholder from '../../media/housePlaceholder.webp';
import {
  ArrowBackOutlined,
  BedOutlined,
  CameraAltOutlined,
  CloseOutlined,
  DirectionsCarFilledOutlined,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import { Cross1Icon } from '@radix-ui/react-icons';
import PropertyDetailsDialog from '../SearchResults/PropertyDetailsDialog';

const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const SelectedPropertyDrawer = ({
  isPropertyDrawerOpen,
  setIsPropertyDrawerOpen,
  selectedProperty,
  isExtraSmallDevice,
  handleCreateAlert,
}) => {
  const [propertyDetailsDrawer, setPropertyDetailsDrawer] = useState(false);

  const handleClosePropertyDetailsDrawer = () => {
    setPropertyDetailsDrawer(false);
  };

  return (
    <div>
      <PropertyDetailsDialog
        handleClosePropertyDetailsDrawer={handleClosePropertyDetailsDrawer}
        propertyDetailsDrawer={propertyDetailsDrawer}
        isExtraSmallDevice={isExtraSmallDevice}
      />
      <Drawer
        open={isPropertyDrawerOpen}
        anchor='left'
        onClose={() => setIsPropertyDrawerOpen(false)}
        variant='persistent'
      >
        {selectedProperty && (
          <div className='flex flex-col w-80 h-full bg-slate-50'>
            <div className='flex absolute z-50 w-full justify-between p-2'>
              <Tooltip
                title={`${selectedProperty.images.length} fotos`}
                enterDelay={500}
                leaveDelay={0}
                placement='right'
                arrow
              >
                <div className='flex  w-16  bg-black opacity-80 rounded-sm gap-2 items-center justify-center'>
                  <CameraAltOutlined
                    className='w-4 h-4 text-white'
                    fontSize='small'
                  />
                  <p className='text-white text-sm'>
                    {selectedProperty.images.length}
                  </p>
                </div>
              </Tooltip>
              <Tooltip
                title='Fechar'
                enterDelay={500}
                leaveDelay={0}
                placement='left'
                arrow
              >
                <div className='bg-black rounded-full shadow-lg hover:bg-gray-500 duration-500'>
                  <IconButton
                    variant='contained'
                    sx={{ color: 'white' }}
                    size='small'
                    onClick={() => setIsPropertyDrawerOpen(false)}
                  >
                    <CloseOutlined />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            <div>
              <Carousel
                responsive={responsive}
                infinite={true}
                slidesToSlide={1}
                className='w-full relative'
                swipeable
                draggable
              >
                {[...selectedProperty.images]
                  .sort((a, b) => {
                    if (a.isCover) return -1;
                    if (b.isCover) return 1;
                  })
                  .map((image) => (
                    <img
                      key={image.name}
                      src={image.url || housePlaceholder}
                      alt='property'
                      className='w-full h-48 object-fill'
                    />
                  ))}
              </Carousel>
            </div>
            <div className='flex flex-col h-16 items-center justify-center bg-slate-100'>
              <p
                className='text-black text-xl font-bold truncate'
                onClick={() => setIsPropertyDrawerOpen(false)}
              >
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(
                  selectedProperty.price +
                    selectedProperty.monthlyTax +
                    selectedProperty.iptu / 12
                )}
              </p>
              <p className='text-black text-sm truncate'>{`${
                selectedProperty.action === 'sale'
                  ? 'Preço:'
                  : 'Aluguel (R$/mês):'
              }`}</p>
            </div>
            <div className='flex flex-col p-2 gap-4'>
              <div className='flex flex-col'>
                <p className='text-base font-bold tracking-tight text-emerald-700 truncate'>
                  {selectedProperty.description}
                </p>
                <p className='font-medium text-sm text-gray-500 truncate'>{`${selectedProperty.address} - ${selectedProperty.houseNumber} - ${selectedProperty.neighborhood}`}</p>
                <p className='font-medium text-sm text-gray-500 truncate'>{`${selectedProperty.neighborhood} - ${selectedProperty.city}`}</p>
              </div>
              <div className='flex flex-col justify-between gap-2 bg-slate-100 border shadow-sm p-2'>
                <div className='flex'>
                  <div className='flex items-center gap-1 w-1/2'>
                    <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.area} m²`}</p>
                  </div>
                  <div className='flex items-center gap-1 w-1/2'>
                    <BedOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.bedrooms
                    } ${!isExtraSmallDevice ? 'quartos' : ''}`}</p>
                  </div>
                </div>
                <div className='flex'>
                  <div className='flex items-center gap-1 w-1/2'>
                    <ShowerOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.bathrooms
                    } ${!isExtraSmallDevice ? 'banheiros' : ''}`}</p>
                  </div>
                  <div className='flex items-center gap-1 w-1/2'>
                    <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                    <p className='text-sm text-gray-500 font-medium'>{`${
                      selectedProperty.parkingSlots
                    } ${!isExtraSmallDevice ? 'vagas' : ''}`}</p>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <button
                  className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
                  onClick={() => setPropertyDetailsDrawer(true)}
                >
                  Ver detalhes do imóvel
                </button>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default SelectedPropertyDrawer;
