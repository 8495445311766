import React, { useEffect, useRef, useState } from 'react';
import NavBar from '../components/Layout/NavBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ServicesPreviewCard from '../components/Cards/ServicesPreviewCard';

import capaFotografar from '../media/capa-fotografar.jpg';
import capaMudar from '../media/capa-mudar.jpg';
import capaAvaliar from '../media/capa-avaliar.jpg';

import {
  evaluatePreviewCardDescription,
  evaluatePreviewCardTitle,
  jobs,
  movePreviewCardDescription,
  movePreviewCardTitle,
  photographerPreviewCardDescription,
  photographerPreviewCardTitle,
} from '../utils/text';
import RecentSearchesCard from '../components/Cards/RecentSearchesCard';
import { formatResults } from '../helpers/formattingFunctions';
import useDebouncedEffect from '../hooks/useDebounceEffect';
import { Cross1Icon } from '@radix-ui/react-icons';
import polygonImg from '../media/polygon-removebg-preview.png';
import { searchAddress } from '../services/mapService';

const HomePage = () => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const ulRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setSelectedResult(null);
        setResults(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const searchNominatim = async (address) => {
    const data = await searchAddress(address);
    setResults(data);
  };

  const clearResults = () => {
    setResults(null);
    setSelectedResult(null);
    setSearchText('');
  };

  const handleInputFocus = () => {
    if (!searchText) {
      setResults({ features: [{ display_name: 'Desenhe uma área no mapa' }] });
    }
  };

  const handleLiClick = (result) => {
    if (result.display_name === 'Desenhe uma área no mapa') {
      // Perform action for 'Desenhe uma área no mapa'
      setResults(null);
      setSelectedResult('desenhar');
      navigate('/desenhar');
    } else {
      setSelectedResult(result);
      setSearchText(formatResults(result));
    }
  };

  useDebouncedEffect(
    () => {
      if (!searchText || searchText.length < 3) return;
      searchNominatim(searchText);
      // cancel previous search if a new keystroke is made before the delay
      return () => {};
    },

    1000,
    [searchText]
  );

  return (
    <div className=' bg-emerald-50'>
      <section className='flex flex-col justify-center items-center p-8 bg-black'>
        <div className='flex flex-col mb-4'>
          <h1 className='text-emerald-400 font-extrabold  text-center text-xl md:text-3xl'>
            Encontre o que deseja
          </h1>
          <h1 className='text-white text-center text-sm md:text-lg'>
            Busque no melhor portal de imóveis do Brasil
          </h1>
        </div>
        <div className='flex gap-4'>
          {/* <div>
            <div className='relative hidden md:flex md:w-96 xs:w-80 w-60 h-12'>
              <input
                type='text'
                onChange={(e) => setSearchText(e.target.value)}
                onFocus={handleInputFocus}
                value={searchText}
                placeholder='Ex.: "Bairro", "Rua", "Cidade"'
                className='placeholder:font-light hidden md:flex md:w-96 xs:w-80 w-60 h-12 px-4 pr-8 text-black font-bold duration-150 bg-white rounded-md hover:bg-gray-100 active:shadow-lg'
              />
              {searchText && (
                <Cross1Icon
                  className='absolute top-4 right-2 cursor-pointer '
                  onClick={clearResults}
                />
              )}
            </div>
            {!selectedResult && results && (
              <ul
                ref={ulRef}
                className='shadow-md hidden md:flex md:flex-col md:w-96 xs:w-80 w-60 absolute bg-white'
              >
                {results.features?.length === 0 && (
                  <li className='flex h-10 md:w-96 xs:w-80 w-60 p-4 items-center rounded-md '>
                    Nenhum resultado encontrado
                  </li>
                )}
                {results.features?.map((result, index) => (
                  <li
                    key={index}
                    onClick={() => handleLiClick(result)}
                    className='flex h-10 md:w-96 xs:w-80 w-60 p-4 items-center rounded-md hover:bg-emerald-100 cursor-pointer'
                  >
                    <p className='text-ellipsis whitespace-nowrap overflow-hidden text-base'>
                      {result.display_name ? (
                        <div className='flex gap-2'>
                          <img src={polygonImg} width={20} />{' '}
                          <p className='font-semibold'>{result.display_name}</p>
                        </div>
                      ) : (
                        formatResults(result)
                      )}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div> */}
          <button
            onClick={() => navigate(`/resultado-busca?action=rent`)}
            className='md:px-14 xs:px-10 px-5 py-3 text-black font-bold duration-150 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg'
          >
            Alugar
          </button>
          <button
            onClick={() => navigate('/resultado-busca?action=sale')}
            className='md:px-12 xs:px-8  px-3 py-3 text-black font-bold duration-150 bg-emerald-400 rounded-md hover:bg-emerald-500 active:shadow-lg'
          >
            Comprar
          </button>
        </div>
      </section>
      <section className='mt-12 mx-auto max-w-screen-lg'>
        <div className='border-2 rounded-md p-8 mx-6 border-black border-opacity-15 shadow-xl'>
          <div>
            <h1 className='text-gray-800 text-xl sm:text-3xl font-semibold'>
              Buscas recentes
            </h1>
          </div>
          <ul className='mt-6 space-y-3'>
            {jobs.map((item, idx) => (
              <li
                key={idx}
                className='p-3 bg-white rounded-md hover:scale-105 hover:shadow-lg shadow-md duration-200 cursor-pointer'
              >
                <RecentSearchesCard item={item} />
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className='flex flex-col justify-center items-center my-20 gap-12'>
        <div className='w-3/4 '>
          <h1 className='text-black font-semibold  text-center text-xl md:text-3xl'>
            Serviços essenciais para seu imóvel
          </h1>
        </div>
        <div className='flex flex-col items-center justify-center gap-12 lg:flex-row'>
          <ServicesPreviewCard
            src={capaFotografar}
            title={photographerPreviewCardTitle}
            description={photographerPreviewCardDescription}
            badge='Fotos Profissionais'
            page='fotografar'
          />
          <ServicesPreviewCard
            src={capaMudar}
            title={movePreviewCardTitle}
            description={movePreviewCardDescription}
            badge='Mude-se com rapidez'
            page='mudar'
          />
          <ServicesPreviewCard
            src={capaAvaliar}
            title={evaluatePreviewCardTitle}
            description={evaluatePreviewCardDescription}
            badge='Profissionais qualificados'
            page='avaliar'
          />
        </div>
      </section>
    </div>
  );
};

export default HomePage;
